import con from "../../../connection";
var path = '/mfa/auth';
var mfa = function (url) { return function () {
    return {
        post: function (param) {
            return con.post(url + path, param);
        },
    };
}; };
export default mfa;
