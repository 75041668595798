import con from '../../../../connection';
var path = '/operations';
var operations = function (url) { return function () { return ({
    // 操作ログ登録
    post: function (param) { return con.post(url + path, param); },
    /** ログアウト */
    logout: function () { return con.post(url + path, {
        scene_id: 3,
        sub_scene_id: 31,
        action_id: 2,
    }); },
    /** 設定-企業設定 */
    companySetting: function () { return con.post(url + path, {
        scene_id: 8,
        sub_scene_id: 15,
        action_id: 5,
    }); },
    /** 設定-個人設定-表示設定-押下 */
    personalSettingDisplaySettingClick: function () { return con.post(url + path, {
        scene_id: 8,
        sub_scene_id: 16,
        sub_scene2_id: 12,
        action_id: 1,
    }); },
    /** 設定-個人設定-表示設定-実行 */
    personalSettingDisplaySettingDo: function () { return con.post(url + path, {
        scene_id: 8,
        sub_scene_id: 16,
        sub_scene2_id: 12,
        action_id: 5,
    }); },
    /** 設定－個人設定－パスワード変更-押下 */
    personalSettingChangePasswordClick: function () { return con.post(url + path, {
        scene_id: 8,
        sub_scene_id: 16,
        sub_scene2_id: 13,
        action_id: 1,
    }); },
    /** 設定－個人設定－パスワード変更-実行 */
    personalSettingChangePasswordDo: function () { return con.post(url + path, {
        scene_id: 8,
        sub_scene_id: 16,
        sub_scene2_id: 13,
        action_id: 5,
    }); },
}); }; };
export default operations;
