import con from '../../../../../connection';
var path = '/reinvite';
var reinvite = function (url) { return function () {
    return {
        post: function (param) {
            return con.post(url + path, param);
        }
    };
}; };
export default reinvite;
