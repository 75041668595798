import { useCallback, useMemo } from "react"
import { IspIdType, CompanyIdType } from '../models/logs/log-type';
import { IspLogOperation } from '../models/logs/isp-log-operation';
import { CompanyLogOperation } from '../models/logs/company-log-operation';

export const useAsyncLogOperation = () => {
  const isp = useCallback(async(type: IspIdType) => new Promise<void>((resolve) => {
    IspLogOperation(type, () => resolve());
  }), [IspLogOperation]);
  const company = useCallback(async(type: CompanyIdType) => new Promise<void>((resolve) => {
    CompanyLogOperation(type, () => resolve());
  }), [CompanyLogOperation]);

  return useMemo(() => ({
    isp,
    company,
  }), [isp, company])
}

export const useAutoSelectLogOperation = (mode: 'admin' | 'company') => {
  const operation = useAsyncLogOperation();
  return useMemo(() => {
    return mode === 'company' ? operation.company : operation.isp;
  }, []);
}