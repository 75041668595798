import { useCallback, useState } from 'react';
import { Button } from '../../ui/button/button';
import './two-step-verification.scss';
import { push } from '@lagunovsky/redux-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { Spinner } from '../../ui/spinner/spinner';
import { useDidMount } from '../../../hooks/life-cycle';
import { apiData } from '../../../managers/api-manager';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { useAppDispatch } from '../../../app/hooks';

export const TwoStepVerification = () => {
  const dispatch = useAppDispatch();
  const [isCheck, setIsCheck] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleClick = useCallback(() => {
    dispatch(push(RoutingPath.apiCompanyLogin));
  }, []);

  useDidMount(() => {
    /* トークン認証API発火 */
    const token = QueryParamFormatter.queryParse().token;
    console.log(token);
    apiData.mfa().post({ token }).catch(() => {
      setIsError(false);
    }).finally(() => {
      setIsCheck(true);
    })
  })

  if (!isCheck) {
    return (
    <div className="dialog_wrap direct_access password">
      <Spinner />
    </div>
    )
  }

  return (
    !isError ? (
      <div className="dialog_wrap direct_access password">
        <div className="dialog">
          <header>
            二段階認証が完了しました。
          </header>
          <div className="dialog_body">
            <div className="text_box">
              ブラウザを閉じてください
            </div>
            <footer>
            </footer>
          </div>
        </div>
      </div>
    ) : (
      <div className="dialog_wrap direct_access add_back">
        <div className="dialog">
          <header>このURLは無効です</header>
          <div className="dialog_body">
            <div className="inner">
              <div className="text_box">
                このURLは既に使用されたか、無効なURLです。<br />
                再度ログインをお試しください。
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}