import con from "../../../../../connection";
var path = '/destinations';
export var destinations = function (url) { return function () {
    var fullPath = "" + url + path;
    return {
        get: function () { return con.get(fullPath, {}); },
        post: function (param) { return con.post(fullPath, param); }
    };
}; };
export default destinations;
