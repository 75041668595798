import con from '../../../connection';
import members from './members';
import comments from './comments';
import files from './files';
var path = '/contacts';
var contacts = function (url) { return function (contact_id) { return ({
    get: contact_id
        ? function (options) { return con.get(url + path + "/" + contact_id, {}, options === null || options === void 0 ? void 0 : options.noError, options === null || options === void 0 ? void 0 : options.noNetworkError); }
        : function (param, options) { return con.get(url + path, param, options === null || options === void 0 ? void 0 : options.noError, options === null || options === void 0 ? void 0 : options.noNetworkError); },
    post: function (param, options) { return con.post(url + path, param, options === null || options === void 0 ? void 0 : options.noError); },
    files: files(url + path),
    comments: comments(url + path + '/' + contact_id),
    members: members(url + path + '/' + contact_id),
}); }; };
export default contacts;
