import { cloneDeep } from 'lodash';
import { GetResponseCompanyDetailAuthorityDataEntity } from '../../api/api/admin-web/companies/authorities';
type Check<T = {}> = T & { 
  checked: boolean,
  id: null | number,
  toggle: () => AppAuthModel,
  defaultValue: boolean,
 };

type AuthorityGetChildDataEntity = {
  authority: string,
  value: boolean,
  id: number | null,
}

type AuthorityGetResponseDataEntity = {
  system: string,
  value: boolean,
  id: number | null,
  authorities: AuthorityGetChildDataEntity[],
}

type AuthChildDataEntity = {
  authority: string,
  description: string,
};

type AuthParentDataEntity<T = {}> = {
  authorities: (AuthChildDataEntity & T)[],
  description: string,
  system: string,
};

type AuthModelData = Check<AuthParentDataEntity<Check>>;

export class AppAuthModel {
  list: AuthModelData[] = [];
  private restoreUserData: GetResponseCompanyDetailAuthorityDataEntity[] = [];
  
  createList(origin: AuthParentDataEntity[], data?: AuthorityGetResponseDataEntity[] ) {
    this.list = origin.map((parent) => ({
      ...parent,
      id: null,
      checked: false,
      defaultValue: false,
      toggle: () => this.toggleParent(parent.system),
      authorities: parent.authorities.map((child) => ({ ...child, id: null, checked: false, toggle: () => this.toggleChild(parent.system, child.authority), defaultValue: false })),
    }));
    if (data) {
      this.list.map((parent) => {
        const parentTarget = data.find((target) => target.system === parent.system);
        if (!parentTarget) return parent;
        parent.checked = !!parentTarget.value;
        parent.id = parentTarget.id;
        parent.defaultValue = parentTarget.value;
        parent.authorities = parent.authorities.map((authData) => {
          const childTarget = parentTarget.authorities.find((target) => authData.authority === target.authority);
          if (!childTarget) return authData;
          authData.checked = !!childTarget.value;
          authData.id = childTarget.id;
          authData.defaultValue = childTarget.value;
          return { ...authData };;
        });
        return { ...parent };
      });
    }
  }

  private toggleParent(system: string) {
    const target = this.list.find((v) => v.system === system);
    if (!target) throw new Error('存在しないシステムです。');
    const changeValue = !target.checked;
    target.checked = changeValue;
    if (!changeValue && (system === 'cadect')) {
      this.resetCadect(true);
    }
    return cloneDeep(this);
  }

  private toggleChild(system: string, childAuth: string) {
    const target = this.list.find((v) => v.system === system);
    if (!target) throw new Error('存在しないシステムです。');
    const childTarget = target.authorities.find((v) => v.authority === childAuth);
    if (!childTarget) throw new Error('存在しない権限です。');
    childTarget.checked = !childTarget.checked;
    return cloneDeep(this);
  }

  public resetCadect(initChild: boolean = false) {
    const target = this.list.find((v) => v.system === 'cadect');
    if (!target) return cloneDeep(this);
    target.authorities = target.authorities.map((v) => {
      if (initChild) {
        return { ...v, checked: false }
      } else {
        return v;
      }
    });
    return cloneDeep(this);
  }

  public createJson() {
    const data = this.list.map((parent) => {
      return {
        system: parent.system,
        value: parent.checked,
        id: parent.id,
        authorities: parent.authorities.map((child) => {
          return {
            id: child.id,
            authority: child.authority,
            value: child.checked,
          }
        }),
      };
    });
    if (this.restoreUserData.length) {
      const restoreJoinData = this.restoreUserData.map((parent) => {
        const parentFind = data.find((v) => v.system === parent.system);
        if (!parentFind) return parent;
        return { ...parentFind, authorities: parent.authorities.map((child: any) => {
          const childFind = parentFind.authorities.find((v) => v.authority === child.authority);
          if (!childFind) return child;
          return { ...childFind };
        })}
      });
      return JSON.stringify(restoreJoinData, null, 2);
    } else {
      return JSON.stringify(data, null, 2);
    }
  }
  public getUniqueCadectData() {
    const uniqueList = ['model-eco', 'model-disaster-prevention', 'model-air-conditioning'];
    const findCadect = this.list.find((v) => v.system === 'cadect');
    const uniqueCadectData = findCadect?.authorities?.filter((v) => uniqueList.includes(v.authority));
    if (!findCadect) {
      return null;
    } else {
      const obj = {
        'model-eco': uniqueCadectData?.find((v) => v.authority === 'model-eco'),
        'model-disaster-prevention': uniqueCadectData?.find((v) => v.authority === 'model-disaster-prevention'),
        'model-air-conditioning': uniqueCadectData?.find((v) => v.authority === 'model-air-conditioning'),
      }
      return { ...findCadect, authorities: uniqueCadectData, ...obj }
    }
  }
}
