import con from "../../../../connection";
import authoritiesDetail from './detail/index';
var path = '/authorities';
var authorities = function (url) { return function () {
    return {
        get: function (request) { return con.get("" + url + path, request); },
        post: function (request) { return con.post("" + url + path, request); },
        detail: authoritiesDetail("" + url + path),
    };
}; };
export default authorities;
