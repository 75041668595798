import { dialogAction } from "../../../../../slices/dialog-slice";
import { Input } from "../../../../ui/input/input";
import { Radiobutton } from "../../../../ui/radiobutton/radiobutton";
import { JsonFileDropZone } from "../json-file-drop-zone/json-file-drop-zone";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../../../app/hooks";

export type AuthorizationFormData = {
  kind: boolean,
  File: File | null,
  sort_order: number | null,
}

type Props = {
  isAdd?: boolean,
  callback: (v: Partial<AuthorizationFormData>) => void,
  onChangeFile: (v: File) => void,
  data: AuthorizationFormData,
}

export const AuthorizeFormEdit = (props: Props) => {
  const { isAdd, callback, onChangeFile, data } = props;  
  const { kind, sort_order } = data;
  const dispatch = useAppDispatch();
  return (
    <div className="edit_wrap">
    <div className="edit_box">
      <div className="item_wrap">
        <div className="item_head">
          種別
          <span className="required">必須</span>
        </div>
        <div className="item_cnt flex">
          <Radiobutton checked={kind} label="共通" onClick={() => callback({ kind: true })} />
          <Radiobutton checked={!kind} label="機能別" onClick={() => callback({ kind: false })} />
        </div>
      </div>
      <div className="item_wrap">
        <div className="item_head">
          並び順
          <span className="required">必須</span>
        </div>
        <div className="item_cnt">
          <div className="input_wrap">
            <Input
              type="number"
              value={data.sort_order ?? undefined}
              style={{ textAlign: 'right', width: '100px' }}
              onChange={(e) => {
                const count = Number(e.target.value) || 0;
                if (count >= 0) {
                  callback({ sort_order: Number(e.target.value)})
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="item_wrap">
        <div className="item_head">
          jsonファイル
          {isAdd && <span className="required">必須</span>}
        </div>
        <div className="item_cnt flex" style={{ flexDirection: 'column', alignItems: 'start', gap: '0.25rem' }}>
          <Input
            value={data.File?.name ?? ''}
            placeholder="ファイルを選択"
            style={{ caretColor: 'transparent' }} 
            onClick={() => {
              dispatch(dialogAction.push({
                outClickPop: true,
                title: '',
                element: <JsonFileDropZone isAdd={isAdd} callback={(v) => {
                  callback({ File: v});
                  onChangeFile(v);
                }} />,
                className: "json_upload_dialog",
              }));
            }}
          />  
        </div>
      </div>
    </div>
  </div>

  )
}