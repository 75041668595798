import { apiAdmin, ApiManager } from '../../managers/api-manager';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IspOrganizationSlicerState = {};

const initialState: IspOrganizationSlicerState = {};


export const apiIspOrganizationsSlice = createSlice({
  name: 'api-isp-organizations',
  initialState,
  reducers: {
    register: (state, action: PayloadAction<{
      companyCode: string,
      param: {
        organization_name: string;
        is_not_address_copy: boolean;
        zip_code: string;
        pref_id: number;
        address: string;
        others: string;
        parent_organization_code: string;
        parent_hierarchy: number;
      },
      callback: {
        success: () => void,
      },
    }>) => {
      apiAdmin.companies(action.payload.companyCode).organizations().post(action.payload.param)
      .then ((res) => {
          action.payload.callback.success();
      })
      .catch((e) => {
        ApiManager.errorFunc(e);
      });
    },
    update: (state, action: PayloadAction<{
      companyCode: string,
      organization_code: string,
      param: {
        organization_name: string;
        is_not_address_copy: boolean;
        zip_code: string;
        pref_id: number;
        address: string;
        others: string;
      },
      callback: {
        success: () => void,
      }, 
    }>) => {
      apiAdmin.companies(action.payload.companyCode).organizations(action.payload.organization_code).post(action.payload.param)
      .then ((res) => {
          action.payload.callback.success();
      })
      .catch((e) => {
        ApiManager.errorFunc(e);
      });
    },
  },
});

export const apiIspOrganizationsAction = apiIspOrganizationsSlice.actions;
export const apiIspOrganizationsReducer = apiIspOrganizationsSlice.reducer;