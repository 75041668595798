import con from '../../../../connection';
var path = '/auth';
var auth = function (url) { return function () {
    return {
        post: function (param) {
            return con.post(url + path, param);
        },
    };
}; };
export default auth;
