import React, { useCallback, useEffect, useRef, useState } from 'react';
import { push } from '@lagunovsky/redux-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import iconMenu from '../../../assets/images/common/icon_menu.svg';
import iconLogout from '../../../assets/images/common/icon_logout.svg';
import iconPass from '../../../assets/images/common/icon_pass.svg';
import iconHuman from '../../../assets/images/common/icon_human.svg';
import iconHelp from '../../../assets/images/common/icon_help.svg';
import iconIspGuide from '../../../assets/images/common/icon_isp_guide.svg';
import iconStartGuide from '../../../assets/images/common/icon_start_guide.svg';
import iconAdminGuide from '../../../assets/images/common/icon_admin_guide.svg';
import dummy from '../../../assets/images/common/icon_uesr.svg';

import { apiAdmin, apiCompany } from '../../../managers/api-manager';
import { useDidMount } from '../../../hooks/life-cycle';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { persistor } from '../../../app/store';
import './header.scss';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { useIsLogin } from './hooks/use-is-login';

type headerProps = {
  apiManger?: 'admin' | 'company';
};

export const Header = (props: headerProps) => {
  const { apiManger } = props;
  const dispatch = useAppDispatch();
  const { startGuideUrl, adminGuideUrl, manuelUrl, totalGuideUrl } = useAppSelector((state) => state.serverEnv);
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);
  const { companyUserIcon } = useAppSelector((state) => state.loginCompanyUserInfo);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenHelp, setIsOpenHelp] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);
  const helpRef = useRef<HTMLUListElement>(null);
  const isLogout = useRef<boolean>(false);
  const islogin = useIsLogin({mode: (apiManger as any)});
  /** ログアウト */
  const handleClickLogOut = useCallback(
    () => {
      isLogout.current = true;
      if (apiManger === 'company') {
        CompanyLogOperation('settingLogOutClick', () => {
          apiCompany.logout().post().then(() => {
            persistor.purge().then(response => console.log(response));
            dispatch(push(RoutingPath.apiCompanyLogin));
            // FIXME α抜けたら起こす
            // window.location.reload();
          });
        });
      } else {
        apiAdmin.logout().post().then(() => {
          persistor.purge().then(response => console.log(response));
          dispatch(push(RoutingPath.apiAdminLogin));
          // FIXME α抜けたら起こす
          // window.location.reload();
        });
      }
    },
    [],
  );
  /** ユーザー設定 */
  const handleClickSetting = useCallback(
    () => {
      CompanyLogOperation('settingProfileClick', () => {
        dispatch(push(RoutingPath.companyUserSetting));
      });
    },
    [],
  );
  /** パスワード変更 */
  const handleClickEditPassword = useCallback(
    () => {
      const logOperation = apiManger === 'company' 
      ? CompanyLogOperation
      : IspLogOperation;

      const path = apiManger === 'company'
      ? RoutingPath.companyEditPassword
      : RoutingPath.adminEditPassword;
      
      logOperation('settingPasswordClick', () => {
        dispatch(push(path));
      })

    },
    [],
  );
  // TODO: ここに読み込んだリンク先への遷移を入れる
  const handleClickGuide = useCallback(
    (type: 'admin' | 'admin' | 'start' | 'total') => {
      switch (type) {
        case 'admin':
          window.open(manuelUrl);
          break;
        case 'admin':
          window.open(adminGuideUrl);
          break;
        case 'start':
          window.open(startGuideUrl);
          break;
        case 'total':
          window.open(totalGuideUrl);
          break;
        default:
          break;
      }
    },
    [],
  );

  useDidMount(() => {
    islogin.getIsLogin();
  });

  /**
   * ドロップダウンメニューの状態を監視
   */
  useEffect(() => {
    isOpenMenu && menuRef.current?.focus();
  }, [isOpenMenu]);
  useEffect(() => {
    isOpenHelp && helpRef.current?.focus();
  }, [isOpenHelp]);
  
  return (
    <header>
      {apiManger === 'company' ?
        // 企業管理
        <>
          <h1>{companyUserInfo.company_name}</h1>
          <div>
            <img
              className="thum"
              src={companyUserIcon.Filedata ? companyUserIcon.Filedata : dummy}
              alt={`${companyUserInfo.display_name}さん`}
            />
            {companyUserInfo.display_name} さんでログイン中
          </div>
          <button onClick={() => setIsOpenMenu(!isOpenMenu)}>
            <img
              src={iconMenu}
              alt="メニュー"
            />
          </button>
          {isOpenMenu &&
            <ul
              className="user_box"
              onBlur={() => setIsOpenMenu(false)}
              ref={menuRef}
              tabIndex={1}
            >
              <li>
                <a
                  onClick={handleClickSetting}
                >
                  <img
                    src={iconHuman}
                    alt="表示設定"
                  />
                  表示設定
                </a>
              </li>
              <li>
                <a
                  onClick={handleClickEditPassword}
                >
                  <img
                    src={iconPass}
                    alt="パスワード変更"
                  />
                  パスワード変更
                </a>
              </li>
              <li>
                <a
                  onClick={handleClickLogOut}
                >
                  <img
                    src={iconLogout}
                    alt="ログアウト"
                  />
                  ログアウト
                </a>
              </li>
            </ul>
          }
          <button
            className={'btn_help'}
            onClick={() => setIsOpenHelp(!isOpenHelp)}
          >
            <img
              src={iconHelp}
              alt="ヘルプ"
            />
          </button>
          {isOpenHelp &&
            <ul
              className="user_box"
              onBlur={() => setIsOpenHelp(false)}
              ref={helpRef}
              tabIndex={1}
            >
              <li>
                <a
                  onClick={() => handleClickGuide('start')}
                >
                  <img
                    src={iconStartGuide}
                    alt="スタートガイド"
                  />
                  スタートガイド
                </a>
              </li>
              <li>
                <a
                  onClick={() => handleClickGuide('admin')}
                >
                  <img
                    src={iconAdminGuide}
                    alt="管理者ガイド"
                  />
                  管理者ガイド
                </a>
              </li>
              {/*TODO: step2にてGOが出るの待ち(2022/08/22)*/}
              {/*<li>*/}
              {/*  <a*/}
              {/*    onClick={() => handleClickGuide('total')}*/}
              {/*  >*/}
              {/*    <img*/}
              {/*      src={iconTotalGuide}*/}
              {/*      alt="PSPJトータルサポートサイト"*/}
              {/*    />*/}
              {/*    PSPトータルサポートサイト*/}
              {/*  </a>*/}
              {/*</li>*/}
            </ul>
          }
        </>
        :
        // ISP管理
        <>
          <h1>MTH管理ウェブ</h1>
          <div>{userInfo.family_name} {userInfo.name} さんでログイン中</div>
          <button onClick={() => setIsOpenMenu(!isOpenMenu)}>
            <img
              src={iconMenu}
              alt="メニュー"
            />
          </button>
          {isOpenMenu &&
            <ul
              className="user_box"
              onBlur={() => setIsOpenMenu(false)}
              ref={menuRef}
              tabIndex={1}
            >
              <li>
               <a
              onClick={handleClickEditPassword}
                >
                  <img
                    src={iconPass}
                    alt="パスワード変更"
                  />
                  パスワード変更
                </a>
              </li> 
              <li>
                <a
                  onClick={handleClickLogOut}
                >
                  <img
                    src={iconLogout}
                    alt="ログアウト"
                  />
                  ログアウト
                </a>
              </li>
            </ul>
          }
          <button
            className={'btn_help'}
            onClick={() => setIsOpenHelp(!isOpenHelp)}
          >
            <img
              src={iconHelp}
              alt="ヘルプ"
            />
          </button>
          {isOpenHelp &&
            <ul
              className="user_box"
              onBlur={() => setIsOpenHelp(false)}
              ref={helpRef}
              tabIndex={1}
            >
              <li>
                <a
                  onClick={() => handleClickGuide('admin')}
                >
                  <img
                    src={iconIspGuide}
                    alt="オンラインマニュアル"
                  />
                  オンラインマニュアル
                </a>
              </li>
            </ul>
          }
        </>
      }
    </header>
  );
};
