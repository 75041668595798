import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../ui/button/button';
import { push } from '@lagunovsky/redux-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { SortListType, Table } from '../../table/table';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { TableElement } from '../../table/table-element';
import { Pager } from '../../ui/pager/pager';
import { dialogAction } from '../../../slices/dialog-slice';
import { apiAdminCommonAction } from '../../../slices/api-isp/api-isp-common-slicer';
import { apiCompany, apiAdmin, ApiManager } from '../../../managers/api-manager';
import { CompanyUserDetail, CompanyUserBody } from '../../../models/company-user-data';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { Input } from '../../ui/input/input';
import { DateFormatter } from '../../../utilities';
import { apiCompanyCommonAction } from '../../../slices/api-company/api-company-common-slicer';
import { companyAction } from '../../../slices/company-slice';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { ApiGetCompanyDetailResponse } from '../../../api/api/company-web/companies/api-get-company-detail.response';
import { ApiGetCompanyMembersResponse } from '../../../api/api/admin-web/companies/members/api-get-company-members.response';
import { useParams } from 'react-router-dom';

const ispBreadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.companyList,
  breadcrumbParts.isp.companyDetail,
  breadcrumbParts.isp.companyUserList,
];
const companyBreadcrumbList = [
  breadcrumbParts.company.home,
  breadcrumbParts.company.userList,
];

type ReInviteButtonProps = {
  apiManger: ApiManager,
  handleClickReInvite: () => void,
  handleClickCancelInvite: () => void,
  isOwner?: boolean,
}

const ReInviteButton = (props: ReInviteButtonProps) => {
  const {
    apiManger,
    handleClickReInvite,
    handleClickCancelInvite,
    isOwner,
  } = props;
  if (apiManger.type === 'company') return (
    !isOwner ? (
      <Button
        size="small"
        color="secondary"
        label="再招待"
        onClick={() => handleClickReInvite()}
      />
    ) : (
      <></>
    )
  );
  return (
    <div style={{ width: '100%' }}>
      <Button
        size="small"
        color="secondary"
        label="再招待"
        onClick={() => handleClickReInvite()}
      />
      {!isOwner && (
        <Button
          size="small"
          color="alert"
          label="取消し"
          onClick={() => handleClickCancelInvite()}
          style={{ width: '48px', height: '27px' }}
        />
      )}
    </div>
  )
}

type Props = {
  companyCode?: string,
} & PageComponentDefaultProps;

export const CompanyUserList = (props: Props) => {
  const { apiManger } = props;
  const { companyCode } = useParams();
  const { industryList, occupationList } = useAppSelector((state) => ({
    industryList: state.commonData.industryList,
    occupationList: state.commonData.occupationList,
  }));
  const { breadcrumbName } = useAppSelector((state) => state.company);
  const [isCheckRetire, setIsCheckRetire] = useState(false);
  const dispatch = useAppDispatch();

  const [companyUserList, setCompanyUserList] = useState<CompanyUserDetail[]>([]);

  const [companyUserBody, setCompanyUserBody] = useState<CompanyUserBody>({
    current_page: 0,
    data: null,
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    links: null,
    next_page_url: '',
    path: '',
    per_page: 0,
    prev_page_url: '',
    to: 0,
    total: 0,
  });

  const [breadcrumbList, setBreadcrumbList] = useState(
    apiManger.type === 'admin' ?
      ispBreadcrumbList :
      companyBreadcrumbList,
  );
  //　表示データの全件数
  const totalData = companyUserBody.total;
  // 一度に表示するデータの件数
  const perData = companyUserBody.per_page;
  // 現在のページ
  const [currentPage, setCurrentPage] = useState(companyUserBody.current_page);
  const [queryFlag, setQueryFlag] = useState(true);
  // 表示開始のデータ番号
  const totalPage: number = useMemo(() => {
    const _totalPage = totalData % perData ?
      Math.floor(totalData / perData) : // 合計のページ数
      Math.floor(totalData / perData) - 1; // 合計のページ数(件数が丁度ページいっぱいで収まる時)
    return _totalPage;
  }, [totalData, perData]);
  // ソートキーの命名
  const sortKey = {
    Organization: 1,
    occupation: 2,
    industry: 3,
    name: 4,
    createdAt: 5,
  };
  // 昇降の明示
  const highlowKey = {
    high: 0,
    low: 1,
  };

  const [sortBy, setSortBy] = useState(sortKey.createdAt);
  const [highlow, setHighlow] = useState(highlowKey.high);
  const [index, setIndex] = useState(6);
  const [activeSort, setActiveSort] = useState('active');
  const [resolveFlag, setResolveFlag] = useState(false);
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);

  const handleClickAddUser = useCallback(
    () => {
      if (apiManger.type === 'admin') {
        IspLogOperation('companyUserAddClick', () => {
          const path = RoutingPath.adminCompanyInvitationUser
            .replace(':companyCode', companyCode!);
          const query = QueryParamFormatter.queryCompose({ p: currentPage });
          dispatch(push(path + '?' + query));
        });
      } else {
        CompanyLogOperation('companyUserAddClick', () => {
          const path = RoutingPath.companyInvitationUser;
          const query = QueryParamFormatter.queryCompose({ p: currentPage });
          dispatch(push(path + '?' + query));
        });
      }
    },
    [currentPage],
  );

  /** 退職者表示 */
  const handleClickShowRetire = useCallback(
    () => {
      setIsCheckRetire(!isCheckRetire);
    },
    [isCheckRetire],
  );

  const handleClickEditUser = useCallback(
    (memberId: string, companyCode: string) => {
      if (apiManger.type === 'admin') {
        IspLogOperation('companyUserEditClick', () => {
          const path = RoutingPath.adminCompanyUserEdit
            .replace(':companyCode', companyCode)
            .replace(':memberId', memberId);
          const query = QueryParamFormatter.queryCompose({ p: currentPage });
          dispatch(push(path + '?' + query));
        });
      } else {
        CompanyLogOperation('companyUserEditClick', () => {
          const path = RoutingPath.companyUserEdit
            .replace(':companyCode', companyCode)
            .replace(':memberId', memberId);
          const query = QueryParamFormatter.queryCompose({ p: currentPage });
          dispatch(push(path + '?' + query));
        });
      }
    },
    [currentPage],
  );

  const handleClickReInvite = (memberId: string) => {
    const apiModule = apiManger.type === 'company'
    ? apiCompany
    : apiAdmin.companies(companyCode);

    dispatch(dialogAction.pushMessage({
      title: '完了',
      message: ['このユーザーを再招待しますか'],
      buttons: [
        {
          label: 'キャンセル',
          callback: () => {
            dispatch(dialogAction.pop());
          },
        }
        , {
          label: 'OK',
          callback: () => {
            dispatch(dialogAction.pop());
            apiModule.members(memberId).reinvite().post({
              pass: `#/companies/${companyCode}/members/add/`,
            }).then((res) => {
              dispatch(dialogAction.pushSendMail({
                message: [
                  '招待メールを送信しました。',
                ],
                callback: () => {
                  dispatch(dialogAction.pop());
                },
              }));
            }).catch((e) => ApiManager.errorFunc(e));
          },
        }],
    }));
  };

  const handleClickReinviteCancel = (memberId: string) => {
    if (apiManger.type === 'company') {
      throw new Error('company mode is not authorization !!');
    }
    dispatch(dialogAction.pushMessage({
      title: '確認',
      message: ['このユーザーの招待を取消しますか'],
      buttons: [
        {
          label: 'キャンセル',
          callback: () => {
            dispatch(dialogAction.pop());
          },
        }
        , {
          label: 'OK',
          callback: () => {
            dispatch(dialogAction.pop());
            if (apiManger.type === 'admin') {
              // ユーザー削除APi
              apiAdmin.companies(companyCode).members(memberId).delete()
              .then(() => {
                getCompanyListAdmin();
                dispatch(dialogAction.pushMessage({
                  title: '完了',
                  message: ['招待の取消しが完了しました。'],
                  buttons: [
                    {
                      label: 'OK',
                      callback: () => {
                        dispatch(dialogAction.pop());
                      },
                    }],
                }));
              })
              .catch((e: any) => ApiManager.errorFunc(e));
            }
          },
        }],
    }));
  }

  /** 表示するページをセット */
  const handlePaginate = useCallback((page: number) => {
    setCurrentPage(page * perData);
  }, [currentPage]);
  /** パンくず用意 */
  useEffect(() => {
    let unmounted = false;
    if (apiManger.type === 'admin') {
      // ISP管理
      if (!userInfo.user_id) {
        return;
      }
      // dispatch(apiIspCommonAction.getIndustryList());
      dispatch(apiAdminCommonAction.getOccupationList());
      /** 企業名取得 */
      if (!breadcrumbName) {
        (apiAdmin.companies(companyCode).get() as unknown as Promise<ApiGetCompanyDetailResponse>)
          .then((v) => {
            if (!unmounted) {
              const Data = v.body.data;
              const path = RoutingPath.adminCompanyDetail
                .replace(':companyCode', companyCode!);
              dispatch(companyAction.setBreadcrumbName({
                breadcrumbName: Data.company_name,
              }));
              breadcrumbParts.isp.companyDetail.route = path;
              breadcrumbParts.isp.companyDetail.label = Data.company_name.length > 20 ?
                Data.company_name.slice(0, 20) + '...' :
                Data.company_name;
              setBreadcrumbList([
                breadcrumbParts.isp.home,
                breadcrumbParts.isp.companyList,
                breadcrumbParts.isp.companyDetail,
                breadcrumbParts.isp.companyUserList,
              ]);
              setResolveFlag(true);
            }
          }).catch((e) => ApiManager.errorFunc(e));
        } else {
        const path = RoutingPath.adminCompanyDetail
          .replace(':companyCode', companyCode!);
        breadcrumbParts.isp.companyDetail.route = path;
        breadcrumbParts.isp.companyDetail.label = breadcrumbName.length > 20 ?
          breadcrumbName.slice(0, 20) + '...' :
          breadcrumbName;
        setBreadcrumbList([
          breadcrumbParts.isp.home,
          breadcrumbParts.isp.companyList,
          breadcrumbParts.isp.companyDetail,
          breadcrumbParts.isp.companyUserList,
        ]);
        setResolveFlag(true);

      }
    } else {
      // 企業管理
      if (!companyUserInfo.member_id) {
        return;
      }
      dispatch(apiCompanyCommonAction.getIndustryList());
      dispatch(apiCompanyCommonAction.getOccupationList());
      setBreadcrumbList(companyBreadcrumbList);
      setResolveFlag(true);
    }
    return () => {
      unmounted = true;
    };
  }, []);
  /** 一覧取得 */
  const getCompanyListAdmin = (unmounted: boolean = false) => {
    // ISP管理一覧取得
    if (!userInfo.user_id) {
      return;
    }
    (apiAdmin.companies(companyCode).members().get({
      is_retirement: isCheckRetire,
      sort_by: sortBy,
      highlow: highlow,
      limit: 20,
      page: currentPage + 1,
    }) as Promise<ApiGetCompanyMembersResponse>)
      .then((v) => {
        if (!unmounted) {
          const body = v.body;
          setCompanyUserList(v.body.data as any);
          setCompanyUserBody(v.body as any);
          if (body.current_page > body.last_page) {
            setCurrentPage(body.last_page - 1);
          }
        }
      }).catch((e) => ApiManager.errorFunc(e));
  }

  useEffect(() => {
    let unmounted = false;
    if (queryFlag) {
      const query = QueryParamFormatter.queryParse().p;
      setCurrentPage(query ? Number(query) : currentPage);
    } else {
      setCurrentPage(currentPage);
    }
    if (apiManger.type === 'admin') {
      getCompanyListAdmin(unmounted);
    } else {
      // 企業ユーザー一覧取得
      if (!companyUserInfo.member_id) {
        return;
      }
      (apiCompany.members().get({
        is_retirement: isCheckRetire,
        sort_by: sortBy,
        highlow: highlow,
        limit: 20,
        page: currentPage + 1,
      }) as Promise<ApiGetCompanyMembersResponse>)
        .then((v) => {
          if (!unmounted) {
            const body = v.body;
            setCompanyUserList(v.body.data as any);
            setCompanyUserBody(v.body as any);
            if (body.current_page > body.last_page) {
              setCurrentPage(body.last_page - 1);
            }
          }
        });
    }
    setQueryFlag(false);
    return () => {
      unmounted = true;
    };
  }, [sortBy, highlow, isCheckRetire, currentPage]);
  /** ソート情報まとめ */
  const sortList: SortListType[] = [
    {
      index: 0, // 何列目
      type: 'up', // 初期の昇降順
      key: sortKey.name, // 何でソートするか
      active: '', // 現在のソートならactive
      callback: () => {
      },
    },
    {
      index: 2,
      type: 'up',
      key: sortKey.Organization,
      active: '',
      callback: () => {
      },
    },
    {
      index: 3,
      type: 'up',
      key: sortKey.occupation,
      active: '',
      callback: () => {
      },
    },
    {
      index: 6,
      type: 'up',
      key: sortKey.createdAt,
      active: '',
      callback: () => {
      },
    },
  ];
  // クリックされたソートを書き換える
  const sort = useMemo(() => {
    return sortList.filter(elm => elm.index === index);
  }, [sortList]);
  sort[0].type = highlow ? 'down' : 'up';
  sort[0].active = activeSort ? 'active' : '';
  // テーブル要素用意
  const head: string[] = [
    '名前',
    'メールアドレス',
    '組織名',
    '職種',
    '権限',
    'ステータス',
    '登録日',
    '',
  ];
  const body: TableElement[][] = [];
  companyUserList
    .slice(0, perData)
    .forEach(element => (
      body.push(
        [
          element.display_name,
          element.email,
          element.organization_name.length > 20 ?
            element.organization_name.slice(0, 20) + '...' :
            element.organization_name,
          occupationList.find((data) => data.id === element.occupation_id)?.name || '',
          (() => {
            switch (String(element.role)) {
              case '1':
                return '管理';
              case '2':
                return 'ユーザー';
              case '3':
                return 'ゲスト';
              default:
                return '';
            }
          })(),
          <div>{!element.is_valid ? '退職' : (element.status ? '' : '招待中')}</div>,
          DateFormatter.date2str(new Date(element.created_at)),
          !element.status ?
            (
              <ReInviteButton
                apiManger={apiManger}
                handleClickCancelInvite={() => handleClickReinviteCancel(element.member_id)}
                handleClickReInvite={() => handleClickReInvite(element.member_id)}
                isOwner={!!element.is_owner}
              />
            ) :
            <Button
              size="small"
              color="secondary"
              label="変更"
              onClick={() => handleClickEditUser(element.member_id, companyCode!)}
            />,
          element.is_valid ? 'active' : 'stop',
        ])
    ));

  return (
    <div
      id="App"
      className="company list"
    >
      <SideBar
        currentPage={apiManger.type === 'company' ? 'company-user-list' : 'company-list'}
        apiManger={apiManger.type}
      />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          {breadcrumbName || resolveFlag ?
            <BreadcrumbList breadcrumbList={breadcrumbList} /> :
            <></>}
          <section>
            <header>
              <h2>ユーザー一覧</h2>
              <div className="count">
                <span>件数</span>
                {totalData}
                <span>件</span>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <div className="checkbox">
                  <Input
                    type="checkbox"
                    id="showRetire"
                    onClick={handleClickShowRetire}
                  />
                  <label
                    htmlFor="showRetire"
                    style={{ marginRight: '10px' }}
                    className="checked"
                  >退職者を表示する</label>
                </div>
                <Button
                  label="ユーザー新規登録"
                  onClick={handleClickAddUser}
                />
              </div>
            </header>
            <div className="table_box">
              <Table
                head={head}
                body={body}
                alignList={['c', 'l', 'l', 'c', 'c', 'c', 'c', 'c']}
                sortList={sortList}
                setSortBy={setSortBy}
                setHighlow={setHighlow}
                setIndex={setIndex}
                setActive={setActiveSort}
              />
            </div>
            <Pager
              totalPage={totalPage}
              currentPage={currentPage}
              callback={setCurrentPage}
              onChange={(page) => handlePaginate(page)}
            />
          </section>
        </div>
      </div>
    </div>
  );
};
