import { useCallback, useState, useMemo } from "react";
import { GetResponseMasterAuthoritiesDataEntity } from "../../../../api/api/admin-web/master/authorities";
import { apiAdmin } from '../../../../managers/api-manager';
import { AuthorizationFormData } from "../dialog/authorize-form-edit/authorize-form-edit";

type Props = {
  isAdd: boolean,
  requestData: AuthorizationFormData,
  editableJsonData: string,
  isDirect: boolean,
}
export const useAuthManager = (props: Props) => {
  const { isAdd, requestData, editableJsonData, isDirect } = props;
  const [init, setInit] = useState(isAdd);
  const [info, setInfo] = useState<GetResponseMasterAuthoritiesDataEntity | null>(null);
  const getDetail = useCallback((id: number, callback: (json: string, resData: GetResponseMasterAuthoritiesDataEntity) => void) => new Promise<void>((resolve) => {
    apiAdmin.master().authorities().detail(id).get().then((res: any) => {
      const data = res.body?.data as GetResponseMasterAuthoritiesDataEntity;
      if (data) {
        setInfo(data);
        const json = JSON.stringify(data.definition, null, 2);
        callback(json, data);
        setInit(true);
      }
    })
  }), []);
  const isAddDisabled = useMemo(() => {
    const valid = [
      !requestData.File, isNaN(Number(requestData.sort_order))
    ];
    return !!valid.find((v) => v === true);
  }, [requestData]);

  const isDirectDisabled = useMemo(() => {
    const isRequire = !!editableJsonData;
    let isJsonData = false;
    try {
      const jsonObj = JSON.parse(editableJsonData);
      if (Array.isArray(jsonObj?.authorities)) {
        return !!jsonObj?.authorities.length
      }
      if ((jsonObj !== null) && (typeof jsonObj === 'object')) {
        const values = Object.values(jsonObj.authorities);
        if (!values.length) return true;
        return !values.every((v) => typeof v === 'string');
      } else {
        return true;
      }
    } catch(e) {
      isJsonData = false;
    }
    return !(isRequire && isJsonData);
  }, [editableJsonData]);

  const isEditDisabled = useMemo(() => {
    return isNaN(Number(requestData.kind));
  }, [requestData]);
  const disabledButton = useMemo(() => {
    if (isAdd) return isAddDisabled;
    return isDirect ? isDirectDisabled : isEditDisabled;
  }, [isAdd, isDirect, isAddDisabled, isEditDisabled, isDirectDisabled]);
  return { info, init, getDetail, disabledButton };
}