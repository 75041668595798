export const RoutingPath = {
  /** ログイン画面 */
  apiAdminLogin: "/login",
  /** ポータル画面 */
  adminPortal: "/portal",
  /** 企業一覧画面 */
  adminCompanyList: "/companies",
  /** パスワード変更画面 */
  adminEditPassword: "/profiles/password",
  /** パスワード再設定要請画面 */
  adminPasswordReissue: "/login/password",
  /** パスワード再設定画面 */
  adminPasswordReset: "/login/password/reset",
  /** パスワード再設定完了画面 */
  adminPasswordResetResult: "/login/password/reset/result",
  /** 企業詳細画面 */
  adminCompanyDetail: "/companies/:companyCode",
  /** 新規企業登録画面 */
  adminCompanyAdd: "/companies/add",
  /** 企業登録変更 */
  adminCompanyEdit: "/companies/:companyCode/edit",
  /** 状況一覧 */
  adminCompanyStatus: "/companies/:companyCode/status",
  /** 組織管理画面 */
  adminOrgManagement: "/companies/:companyCode/organizations",
  /** ユーザー一覧画面 */
  adminUserList: "/users",
  /** ユーザー招待画面 */
  adminInvitationUser: "/users/invite",
  /** ユーザー登録画面 */
  adminUserAdd: "/users/add",
  /** ユーザー登録確認 */
  adminUserAddCheck: "/users/add/check",
  /** ユーザー更新画面 */
  adminUserEdit: "/users/:userId/edit",
  /** 企業ユーザー一覧画面 */
  adminCompanyUserList: "/companies/:companyCode/members",
  /** 企業ユーザー新規登録 */
  adminCompanyUserAdd: "/companies/:companyCode/members/add",
  /** 企業ユーザー新規登録確認 */
  adminCompanyUserAddCheck: "/companies/:companyCode/members/add/check",
  /** 企業ユーザー新規登録完了 */
  adminCompanyUserAddResult: "/companies/:companyCode/members/add/result",
  /** 企業ユーザー情報編集 */
  adminCompanyUserEdit: "/companies/:companyCode/members/:memberId/edit",
  /** 企業ユーザー招待画面 */
  adminCompanyInvitationUser: "/companies/:companyCode/members/invite",
  /** 権限管理画面 */
  adminAuthorization: "/authorization",
  /** トークン認証エラー画面 */
  adminErrorToken: "/errors/token",
  /** ログ出力ページ */
  adminLogExport: "/log",
  /** インフォメーション一覧 */
  adminInfo: "/info",
  /** インフォメーション追加 */
  adminInfoAdd: "/info/add",
  /** インフォメーション詳細 */
  adminInfoDetail: "/info/detail/:infoId",
  /** インフォメーション複製 */
  adminInfoClone: "/info/detail/:infoId/clone",
  /** 通知先設定 */
  adminInfoSelect: "/info/select",
  /** 依頼設定画面 */
  adminRequestSetting: "/request",

  // 企業管理
  /** ログイン画面 */
  apiCompanyLogin: "/login",
  /** パスワード再設定要請画面 */
  companyPasswordReissue: "/login/password",
  /** パスワード再設定画面 */
  companyPasswordReset: "/login/password/reset",
  /** パスワード再設定完了画面 */
  companyPasswordResetResult: "/login/password/reset/result",
  /** 企業管理ポータル画面 */
  companyStatus: "/status",
  /** 組織管理画面 */
  companyOrgManagement: "/organizations",
  /** ユーザー一覧画面 */
  companyUserList: "/members",
  /** ユーザー新規登録 */
  companyUserAdd: "/members/add",
  /** ユーザー新規登録確認 */
  companyUserAddCheck: "/members/add/check",
  /** ユーザー新規登録完了 */
  companyUserAddResult: "/members/add/result",
  /** ユーザー情報編集 */
  companyUserEdit: "/members/:memberId/edit",
  /** ユーザー招待画面 */
  companyInvitationUser: "/members/invite",
  /** トークン認証エラー画面 */
  companyErrorToken: "/errors/token",
  /** 設定画面 */
  companyUserSetting: "/profiles",
  /** パスワード変更画面 */
  companyEditPassword: "/profiles/password",
  /** ログ出力ページ */
  companyLogExport: "/log",
  /** インフォメーション一覧 */
  companyInfo: "/info",
  /** インフォメーション追加 */
  companyInfoAdd: "/info/add",
  /** インフォメーション詳細 */
  companyInfoDetail: "/info/detail/:infoId",
  /** インフォメーション複製 */
  companyInfoClone: "/info/detail/:infoId/clone",
  /** 通知先設定 */
  companyInfoSelect: "/info/select",
  /** 物件一覧 */
  companyArticles: "/articles",
  /** 二段階認証ページ */
  companyTwoStepVerification: "/mfa",
};
