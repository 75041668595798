import con from '../../../connection';
import files from './files';
import v2 from './v2';
import takenaka from './takenaka';
import settings from './settings';
import confirmed from './confirmed';
var path = '/applications';
var applications = function (url) { return function (app_id) { return ({
    files: files("" + url + path),
    v2: v2("" + url + path),
    takenaka: takenaka("" + url + path),
    settings: settings("" + url + path),
    confirmed: confirmed("" + url + path),
    get: app_id
        // 個別取得
        ? function (plan_id) { return con.get(url + path + "/" + plan_id + "/" + app_id, {}); }
        // 一覧取得
        : function (plan_id) { return con.get(url + path + "/" + plan_id, {}); },
    start: function (plan_id, app_kind) { return con.post(url + path + "/" + "start" + "/" + plan_id + "/" + (app_id || "") + "/" + app_kind, {}); },
    end: function (token) { return con.post(url + path + "/" + "end" + "/" + token, {}); },
    delete: function (plan_id) { return con.delete("" + url + path + "/" + app_id + "/" + plan_id, {}); },
    status: function (token) { return ({
        get: function (noError) {
            if (noError === void 0) { noError = false; }
            return con.get(url + path + "/" + token + "/" + "status", {}, noError);
        },
    }); },
    report: function (token, param, options) { return con.post(url + path + '/' + token + '/' + 'report', param, options.noError, options.timeoutMS); },
    /**
     * アプリケーション連携データバージョンアップ (DATA API側バージョンアップ)
     *
     * プランからソフトウェアランチャメニューを開く前に叩いて、連携データのバージョンを最新にするための API
     */
    post: function (planId, options) { return con.post((url + path + '/' + planId), {}, options.noError, options.timeoutMS); }
}); }; };
export default applications;
