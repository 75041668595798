import con from '../../../connection';
var path = '/icon';
var icon = function (url) { return function () {
    return {
        get: function () { return con.get(url + path, {}); },
        post: function (param) {
            return con.post(url + path, param);
        },
        delete: function () { return con.delete("" + url + path, {}); }
    };
}; };
export default icon;
