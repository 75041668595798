import con from '../../../../connection';
var path = '/activate';
var activate = function (url) { return function () {
    return {
        post: function (param) {
            return con.post(url + path, param);
        },
    };
}; };
export default activate;
