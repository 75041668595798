import con from '../../../../connection';
import name from './name/index';
var path = '/templates';
var templates = function (url) { return function (id) { return ({
    name: name("" + url + path + "/" + id),
    get: function () {
        return id
            ? con.get("" + url + path + "/" + id, {})
            : con.get(url + path, {});
    },
    post: function (param) {
        return con.post(url + path + (id ? "/" + id : ''), param);
    },
    delete: function () {
        return con.delete("" + url + path + "/" + id, {});
    },
}); }; };
export default templates;
