import { useCallback, useEffect, useState } from "react";
import { GetResponseMasterOrganizationsDataEntity } from "../../../../api/api/admin-web/master/requests/organizations";
import { apiAdmin, ApiManager } from "../../../../managers/api-manager";

export const useRequestOrganizationsList = (onSuccess: (resData: GetResponseMasterOrganizationsDataEntity[]) => void) => {
  const [organizations, setOrganizations] = useState<GetResponseMasterOrganizationsDataEntity[]>([]);
  const getList = useCallback(() => {
    apiAdmin.master().requests().organizations().get().then((res) => {
      setOrganizations(res.body.data);
      onSuccess(res.body.data);
    }).catch(ApiManager.errorFunc)
  }, []);

  useEffect(() => {
    getList();
  }, []);

  return {
    organizations: {
      originList: organizations,
      setList: setOrganizations,
    },
    getList,
  }
}