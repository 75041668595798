import reset from './reset/index';
import auth from './auth/index';
import set from './set/index';
var path = '/password';
var password = function (url) { return function () { return ({
    reset: reset("" + url + path),
    auth: auth("" + url + path),
    set: set("" + url + path),
}); }; };
export default password;
