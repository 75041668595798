import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { apiCompany, apiAdmin, ApiManager } from '../../../managers/api-manager';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { CompanyData } from '../../../models/company-data';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { Button } from '../../ui/button/button';
import { Checkbox } from '../../ui/checkbox/checkbox';
import { DatePicker } from '../../ui/date-picker/date-picker';
import { Header } from '../../ui/header/header';
import { LabeledForm } from '../../ui/input/labeled-form';
import { Select } from '../../ui/select/select';
import { SideBar } from '../../ui/sidebar/sidebar';
import { ReactSelect, SelectInfoData, ChangeLabelData } from '../../ui/select/react-select';
import { useDidMount } from '../../../hooks/life-cycle';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { CompanyUserDetail } from '../../../models/company-user-data';
import { Radiobutton } from '../../ui/radiobutton/radiobutton';
import './operation-log.scss';
import { cloneDeep } from 'lodash';
import { dialogAction } from '../../../slices/dialog-slice';
import { MultiValue, SingleValue } from 'react-select';
import { Input } from '../../ui/input/input';
import { DateFormatter, ZeroPadding } from '../../../utilities';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import deleteIcon from '../../../assets/images/common/icon_delete.svg';
import iconCalendarOff from '../../../assets/images/common/icon_calendar_off.svg';
import iconCalendarOn from '../../../assets/images/common/icon_calendar_on.svg';
import crossOff from '../../../assets/images/common/cross_off.svg';
import crossOn from '../../../assets/images/common/cross_on.svg';
import { useAutoSelectLogOperation } from '../../../hooks/use-async-log-operation';
import { useLogProduct } from './hooks/use-log-product';
import { ApiGetCompaniesResponse } from '../../../api/api/admin-web/companies/api-get-companies.response';
import { ApiGetCompanyMembersResponse } from '../../../api/api/admin-web/companies/members/api-get-company-members.response';
import { useMijissou } from '../../../hooks/use-mijissou';

export type SelectData = {
  key: string,
  label: string,
};

const ispBreadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.ispLog,
];

const companyBreadcrumbList = [
  breadcrumbParts.company.home,
  breadcrumbParts.company.ispLog,
];

// バリデーション用意
const validations: { [key: string]: Validation } = {
  requireCompany: ValidationFactory('require'),
  checkAfterTodayStartDate: ValidationFactory('require'),
  checkAfterTodayEndDate: ValidationFactory('require'),
  checkStartThanPastDate: ValidationFactory('require'),
};

export const OperationLog = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const breadcrumbList = apiManger.type === 'admin' ? ispBreadcrumbList : companyBreadcrumbList;
  const userNameDisplaySwitch = apiManger.type === 'admin';
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);

  /* Hooks */
  const dispatch = useAppDispatch();
  const logMang = useAutoSelectLogOperation(apiManger.type);
  /* State */
  /* ---- 検索 ---- */
  /* システム選択 */
  const [isAdminSite, setIsAdminSite] = useState(true);
  /* プロダクト選択 */
  // const [cadectIsCheck, setCadectIsCheck] = useState(false);
  // const [stabroIsCheck, setStabroIsCheck] = useState(false);
  // const [aRepo4IsCheck, setARepo4IsCheck] = useState(false);
  // const [seACDIsCheck, setSeACDIsCheck] = useState(false);
  /* 選択企業　*/
  const [selectedCompany, setSelectedCompany] = useState<SingleValue<SelectInfoData> | undefined>(undefined);
  const company = useMemo(() => {
    return selectedCompany?.value ?? '';
  }, [selectedCompany]);
  /* プロダクトリスト関連 */
  const { requestLogProduct, productList, productCheckToggle, initProduct } = useLogProduct({ companyCode: apiManger.type === 'admin' ? company : companyUserInfo.company_code, isPortal: !isAdminSite });
  /* 選択ユーザー */
  const [userMailBody, setUserMailBody] = useState<SingleValue<SelectInfoData> | undefined>(undefined);
  /* 日付　*/
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  /* ログ開始日 */
  const [_startLogDate, setStartLogDate] = useState<Date | null>(null);

  const startLogDate = useMemo(() => {
    if (apiManger.type === 'company') return _startLogDate;
    return null;
  }, [_startLogDate, apiManger]);
  /* -------------- */

  /* 各種リスト */
  const [companyInfoList, setCompanyInfoList] = useState<CompanyData[]>([]);
  const [userList, setUserList] = useState<CompanyUserDetail[]>([]);
  /* DLボタン制御 */
  const [isDisabled, setIsDisabled] = useState(true);
  const [validFlag, setValidFlag] = useState(false);
  // バリデーションのトータルチェック
  const [checkValidation, setCheckValidation] = useState(CheckAllValid(validations));
  /* Memo */
  /* Ref */
  const startRef = useRef<HTMLInputElement>(null);
  const endRef = useRef<HTMLInputElement>(null);
  /* Title */
  const titleText = useMemo(() => apiManger.type === 'admin' ? '企業操作ログ' : '操作ログ', [apiManger.type]);
  /* - 企業プルダウンリスト - */
  const viewList = useMemo<SelectInfoData[]>(() => {
    return companyInfoList.map((v, i) => ({ value: v.company_code, label: v.company_name }));
  }, [companyInfoList]);
  /* - ユーザーリスト - */
  const mailList = useMemo<SelectInfoData[]>(() => {
    return userList.map((v, i) => ({ value: v.member_id, label: v.email }));
  }, [userList]);
  /* - ユーザーリスト メールから名前に変更用の値 - */
  const userInfoList = useMemo<ChangeLabelData[]>(() => {
    return userList.map((v, i) => ({ value: v.member_id, label: v.email, name: v.display_name }));
  }, [userList]);

  const changeDate = useMemo<string>(() => {
    if (!endDate || !_startLogDate) return DateFormatter.date2str(new Date(), 'YYYYMMDD', '-');
    const cloneDate = cloneDeep(endDate);
    cloneDate.setFullYear(cloneDate.getFullYear() - 3);
    const result = cloneDate < _startLogDate ? _startLogDate : cloneDate;

    return DateFormatter.date2str(result, 'YYYYMMDD', '-');
  }, [endDate]);

  // バリデーション用登録時の日付
  const now = useMemo(() => {
    return new Date();
  }, []);
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();

  const todayValid = useMemo(() => {
    return String(year) + ZeroPadding(month, 2) + ZeroPadding(day, 2);
  }, [year, month, day]);

  // - ソートキーの命名 -
  const sortKey = {
    code: 1,
    name: 2,
    storage: 3,
    createdAt: 4,
    stopAt: 5,
  };
  // - 昇降の明示 -
  const highlowKey = {
    high: 0,
    low: 1,
  };
  //  -企業一覧取得用 -
  const companyListKey = {
    is_view: true,
    sort_by: sortKey.createdAt,
    highlow: highlowKey.low,
    limit: 99999,
  };
  // - ユーザー一覧取得用 -
  const memberListKey = {
    is_retirement: true,
    sort_by: sortKey.createdAt,
    highlow: highlowKey.low,
    limit: 99999,
  };

  // - 日付のファーマット -
  const formatDate = (v: Date) => (v.getFullYear() + '-' + (v.getMonth() + 1) + '-' + v.getDate());
  const termDay = (s: string, e: string): number => {
    const start = DateFormatter.str2dateNoTime(s);
    const end = DateFormatter.str2dateNoTime(e) ?? DateFormatter.str2dateNoTime(formatDate(now));
    if (!start || !end) return 0;
    return (Number(end) - Number(start)) / 86400000;
  };
  // const format = (v: string) => (v.getFullYear() + "-" + (v.getMonth() + 1) + "-" + v.getDate());
  // // - 日付のファーマット csvファイル名用 -
  // const formatDateCsv = (v: Date) => (`${v.getFullYear()}` + `${('0' + (v.getMonth() + 1)).slice(-2)}` + `${('0' + v.getDate()).slice(-2)}`);

  /* callback */
  /* - 企業詳細取得 - */
  const getCompanyLogDate = useCallback(async(v: string) => {
    const companyCode = companyInfoList.find((v2) => v2.company_code === v)?.company_code;
    if (!companyCode) return;
    const comp = await apiAdmin.companies(companyCode).get();
    const data = comp.body.data as any;
    return new Date(data.log_start_date);
  }, [companyInfoList])
  // - 企業選択 -
  const changeCompany = useCallback(async(e: SingleValue<SelectInfoData> | MultiValue<SelectInfoData>) => {
    const changeValue = e as SingleValue<SelectInfoData>;
    if (!changeValue) return;
    setSelectedCompany(changeValue);
    setUserMailBody({ value: '', label: '' });
    setStartDate(null);
    setEndDate(null);
    const date = await getCompanyLogDate(changeValue.value);
    if (!date) return;
    setStartLogDate(date);
  }, [viewList, userMailBody, getCompanyLogDate]);

  // - ユーザー選択 -
  const changeUser = useCallback((v: any) => {
    setUserMailBody(v);
  }, [mailList]);

  // - DLボタン -
  // FIXME 消す
  const mijissou = useMijissou();
  const onClickDL = useCallback(() => {
    mijissou.pushMijissou();
    return;
    // logMang('logManagerDlClick');
    // const _company = companyInfoList.find((v) => v.company_code === company);
    // if (apiManger.type === 'admin') {
    // const _user = userList.find((v) => v.member_id === userMailBody?.value && v.company_code === company);
    //   (apiAdmin.logs().operations().company().download().get({
    //     extract_date_start: startDate ? DateFormatter.date2str(new Date(startDate), 'YYYYMMDD', '-') : '',
    //     extract_date_end: endDate ? DateFormatter.date2str(new Date(endDate), 'YYYYMMDD', '-') : DateFormatter.date2str(new Date(), 'YYYYMMDD', '-'),
    //     company_code: _company?.company_code ?? '',
    //     member_id: _user ? _user.member_id : undefined,
    //     ...requestLogProduct,
    //     kind: !isAdminSite,
    //   }) as Promise<any>)
    //   .then((v) => {
    //     const a = document.createElement('a');
    //     const url = URL.createObjectURL(v);
    //     a.download = '操作ログ.csv';
    //     a.href = url;
    //     a.click();
    //     URL.revokeObjectURL(url);
    //     a.remove();
    //   }).catch((error) => {
    //     ApiManager.errorFunc(error);
    //   });
    // } else {
    // const _user = userList.find((v) => v.member_id === userMailBody?.value);
    //   (apiCompany.logs().operations().download().get({
    //     extract_date_start: startDate ? DateFormatter.date2str(new Date(startDate), 'YYYYMMDD', '-') : '',
    //     extract_date_end: endDate ? DateFormatter.date2str(new Date(endDate), 'YYYYMMDD', '-') : DateFormatter.date2str(new Date(), 'YYYYMMDD', '-'),
    //     member_id: _user ? _user.member_id : undefined,
    //     ...requestLogProduct,
    //     kind: !isAdminSite,
    //   }) as Promise<any>)
    //   .then((v) => {
    //     const a = document.createElement('a');
    //     const url = URL.createObjectURL(v);
    //     a.download = '操作ログ.csv';
    //     a.href = url;
    //     a.click();
    //     URL.revokeObjectURL(url);
    //     a.remove();
    //   }).catch((error) => {
    //     ApiManager.errorFunc(error);
    //   });
    // }
  }, [titleText, isAdminSite, viewList, mailList, userMailBody, startDate, endDate, apiManger, logMang, requestLogProduct]);

  const sortInitialize = useCallback(() => {
    initProduct();
    setIsAdminSite(true);
    if (apiManger.type !== 'company') {
      setSelectedCompany(undefined);
      setUserList([]);
      setStartLogDate(null);
    }
    setUserMailBody(undefined);
    setStartDate(null);
    setEndDate(null);
  }, [apiManger.type]);

  /* 検索条件削除 */
  const onClickSortDelete = useCallback(() => {
    sortInitialize();
  }, []);

  // - 指定開始日 -
  const changeStartDate = useCallback((v: Date | null) => {
    setStartDate(v);
    window.setTimeout(() => {
      if (endRef.current) {
        endRef.current.focus();
        endRef.current.blur();
      }
    }, 0)
  }, [endRef]);
  // - 指定終了日 -
  const changeEndDate = useCallback((v: Date | null) => {
    setEndDate(v);
    window.setTimeout(() => {
      if (startRef.current) {
        startRef.current.focus();
        startRef.current.blur();
      }
    }, 0)
  }, [startRef]);

  /* Effect */
  // - 企業選択 一覧取得 -
  useDidMount(() => {
    if (apiManger.type === 'admin') {
      (apiAdmin.companies().get(companyListKey) as Promise<ApiGetCompaniesResponse>)
        .then((v) => {
          if (v.body.total < companyListKey.limit) {
            setCompanyInfoList(v.body.data as any);
          } else {
            companyListKey.limit = v.body.total;
            (apiAdmin.companies().get(companyListKey) as Promise<ApiGetCompaniesResponse>)
              .then((v) => {
                setCompanyInfoList(v.body.data as any);
              });
          }
        });
      delete validations.requireUser;
    } else {
      apiCompany.companies().get().then((v) => setStartLogDate(new Date(v.body.data.log_start_date)));
      delete validations.requireCompany;
    }

    validations['checkAfterTodayStartDate'] = new Validation({
      test: (v: string) =>
        v === '' || Number(v.replace(/-/g, '')) <= Number(todayValid),
      errorMessages: ['今日以降の設定はできません'],
    });
    validations['checkAfterTodayEndDate'] = new Validation({
      test: (v: string) =>
        v === '' || Number(v.replace(/-/g, '')) <= Number(todayValid),
      errorMessages: ['今日以降の設定はできません'],
    });
    setValidFlag(true);
  });

  // - ユーザー名 一覧取得 -
  useEffect(() => {
    if (apiManger.type === 'admin' && companyInfoList.length && company) {
      const companyCode = companyInfoList.find((v) => v.company_code === company)?.company_code;
      if (!companyCode) return;
      (apiAdmin.companies(companyCode).members().get(memberListKey) as Promise<ApiGetCompanyMembersResponse>)
        .then((v) => {
          if (v.body.total < memberListKey.limit) {
            setUserList(v.body.data as any);
          } else {
            memberListKey.limit = v.body.total;
            (apiAdmin.companies(companyCode).members().get(memberListKey) as Promise<ApiGetCompanyMembersResponse>)
              .then((v) => {
                setUserList(v.body.data as any);
              });
          }
        });
      return;
    }

    if (apiManger.type === 'company') {
      (apiCompany.members().get(memberListKey) as Promise<ApiGetCompanyMembersResponse>)
        .then((v) => {
          if (v.body.total < memberListKey.limit) {
            setUserList(v.body.data as any);
          } else {
            memberListKey.limit = v.body.total;
            (apiCompany.members().get(memberListKey) as Promise<ApiGetCompanyMembersResponse>)
              .then((v) => {
                setUserList(v.body.data as any);
              });
          }
        });
    }
  }, [company]);

  useEffect(() => {
    if (!startRef.current || !endRef.current) return;
    validations['checkStartThanPastDate'] = new Validation({
      test: (v: string) =>
        v === '' || Number(startRef.current!.value.replace(/-/g, '')) <= Number(v.replace(/-/g, '')),
      errorMessages: ['指定開始日より過去日の設定はできません'],
    });
  }, [validFlag]);

  useEffect(() => {
    setCheckValidation(CheckAllValid(validations));
  }, [startDate, endDate, company, userMailBody, selectedCompany]);
  useEffect(() => {
    window.setTimeout(() => {
      const condition = apiManger.type === 'admin' ? company : true;
      if ((condition && startDate && !CheckAllValid(validations)) || (condition && endDate && !CheckAllValid(validations))) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }, 10)
  }, [startDate, endDate, userMailBody, company, checkValidation]);

  return (
    <div
      id="App"
      className="operation_log"
    >
      <SideBar
        currentPage="log-Management"
        apiManger={apiManger.type}
      />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList} />
          <div className="inner">
            <section className="">
              <header>
                <h2>{titleText}</h2>
              </header>
              <div className="operation_log__body__wrap edit_wrap">
                <div className="operation_log__body">
                  {<div className="operation_log__body__row row_forms">
                    <LabeledForm
                      label="システム選択"
                      formEle={
                        <div className="forms">
                          <Radiobutton
                            label="管理サイト"
                            onClick={() =>
                              setIsAdminSite(!isAdminSite)
                            }
                            name={'systemAdmin'}
                            checked={isAdminSite}
                          />
                          <Radiobutton
                            label="ポータル"
                            onClick={() =>
                              setIsAdminSite(!isAdminSite)
                            }
                            name={'systemPortal'}
                            checked={!isAdminSite}
                          />
                        </div>
                      }
                    />
                    <Button
                      className="delete_btn"
                      label=""
                      onClick={onClickSortDelete}
                      color="quaternary"
                      icon={<img src={deleteIcon} />}
                      size="large"
                      title="入力内容をリセット"
                    />

                  </div>}
                  {userNameDisplaySwitch &&
                    <div className="operation_log__body__row">
                      <LabeledForm
                        label="企業選択"
                        formEle={(
                          <ReactSelect
                            list={viewList}
                            select={selectedCompany}
                            handleChange={changeCompany}
                            placeholder="企業を選択してください。"
                            noOptionsMessage={'該当する企業が存在しません。'}
                            isClearable
                            formMode={'pulldown'}
                            validations={[validations?.requireCompany]}
                          />
                        )}
                        className="user_name"
                      />
                    </div>}
                  {<div className="operation_log__body__row">
                    <LabeledForm
                      label="ユーザー名"
                      formEle={<ReactSelect
                        list={mailList}
                        select={userMailBody}
                        changeLabelList={userInfoList}
                        isDisabled={apiManger.type === 'admin' && !company}
                        handleChange={changeUser}
                        placeholder={(!!company || apiManger.type === 'company')  ? 'ユーザーメールアドレス' : '企業を選択してください。'}
                        noOptionsMessage={'該当するメールアドレスが存在しません。'}
                        isClearable
                        formMode={'pulldown'}
                      />}
                      className="user_name"
                    />
                  </div>}
                  {<div className="operation_log__body__row row_forms">
                    <LabeledForm
                      label="プロダクト選択"
                      formEle={
                        <div className="forms">
                          { productList.map((data) => (
                            <Checkbox
                              id={data.id}
                              checked={data.checked}
                              onClick={() => productCheckToggle(data.key)}
                              label={data.label}
                              disabled={isAdminSite}
                            />
                          )) }
                        </div>
                      }
                    />
                  </div>}
                  <div className="operation_log__body__row row_period edit_wrap">
                    <div className="period_form__wrap date_box">
                      <div className="period_form">
                        <LabeledForm
                          label="指定開始日"
                          formEle={
                            <Input
                              type="date"
                              readOnly={apiManger.type === 'admin' && !company}
                              onChange={(e) => {
                                changeStartDate(e.target.value ? new Date(e.target.value) : null)
                              }}
                              onClickClearDate={() => changeStartDate(null)}
                              value={startDate ? DateFormatter.date2str(startDate, 'YYYYMMDD', '-') : ''}
                              max={DateFormatter.date2str(new Date(), 'YYYYMMDD', '-')}
                              min={startLogDate ? DateFormatter.date2str(startLogDate, 'YYYYMMDD', '-') : undefined}
                              disabled={apiManger.type === 'admin' && !company}
                              validations={[validations.checkAfterTodayStartDate]}
                              ref={startRef}
                            />
                          }
                        />
                        <div className="period_form__tilde" />
                        <LabeledForm
                          label="指定終了日"
                          formEle={
                            <Input
                              type="date"
                              readOnly={apiManger.type === 'admin' && !company}
                              onChange={(e) => {
                                changeEndDate(e.target.value ? new Date(e.target.value) : null)
                              }}
                              value={endDate ? DateFormatter.date2str(endDate, 'YYYYMMDD', '-') : ''}
                              onClickClearDate={() => changeEndDate(null)}
                              max={DateFormatter.date2str(new Date(), 'YYYYMMDD', '-')}
                              min={startLogDate ? DateFormatter.date2str(startLogDate, 'YYYYMMDD', '-') : undefined}
                              disabled={apiManger.type === 'admin' && !company}
                              validations={[validations.checkAfterTodayEndDate, validations.checkStartThanPastDate]}
                              ref={endRef}
                            />
                          }
                        />
                      </div>
                      <div
                        className="period_form__announce"
                        style={{ opacity: apiManger.type === 'admin' && !company ? 0.5 : 1 }}>
                        ※開始日または終了日のどちらか一方は必ず設定してください
                          </div>
                    </div>
                    <Button
                      label="DL"
                      onClick={onClickDL}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};