import { Route } from "react-router-dom"
import { Routes } from 'react-router';
import { ApiManager } from "../../managers/api-manager";
import { RoutingPath } from "../routing-path";
import { CompanyUserAdd } from "../../components/pages/company/company-user-add";
import { AdminUserAddCheck } from "../../components/pages/user/admin-user-add-check";
import { CompanyUserAddResult } from "../../components/pages/company/company-user-add-result";
import ErrorToken from "../../components/pages/error/error-token";
import { TwoStepVerification } from "../../components/pages/two-step-verification/two-step-verification";

type Props = {
  apiManagerAdmin: ApiManager,
  apiManagerCompany: ApiManager,
}

export const DataRoutes = (props: Props) => {
  const { 
    apiManagerAdmin,
    apiManagerCompany,
   }  = props;
  return (
    <Routes>
      <Route
        path={RoutingPath.adminCompanyUserAdd}
        element={<CompanyUserAdd
          apiManger={apiManagerAdmin}
        />}
      />
      <Route
        path={RoutingPath.adminCompanyUserAddCheck}
        element={<AdminUserAddCheck
          apiManger={apiManagerAdmin}
          isCompanyUser={true}
        />}
      />
      <Route
        path={RoutingPath.adminCompanyUserAddResult}
        element={<CompanyUserAddResult
          apiManger={apiManagerAdmin}
        />}
      />
      <Route
        path={RoutingPath.companyUserAdd}
        element={<CompanyUserAdd apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyUserAddCheck}
        element={<AdminUserAddCheck
          apiManger={apiManagerCompany}
          isCompanyUser={true}
        />}
      />
      <Route
        path={RoutingPath.companyUserAddResult}
        element={<CompanyUserAddResult apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.companyUserAddResult}
        element={<CompanyUserAddResult apiManger={apiManagerCompany} />}
      />
      <Route
        path={RoutingPath.adminErrorToken}
        element={<ErrorToken />}
      />
        {/* 二段階認証 */}
      <Route
        path={RoutingPath.companyTwoStepVerification}
        element={<TwoStepVerification />}
      />
    </Routes>
  )
}
