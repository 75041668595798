import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../../../app/hooks";
import { dialogAction } from "../../../../../slices/dialog-slice";
import { checkJson } from "../../../../../utilities/check-json";
import { Button } from "../../../../ui/button/button";
import { IspLogOperation } from '../../../../../models/logs/isp-log-operation';

type Props = {
  callback: (v: File) => void,
  isAdd?: boolean,
}

export const JsonFileDropZone = (props: Props) => {
  const { isAdd } = props;
  const dispatch = useAppDispatch();
  const [draggable, setDraggable] = useState(false);

  const validationJson = (fileList: FileList) => new Promise<File>((resolve) => {
    const originFiles = Array.from(fileList);
    const files = Array.from(fileList).filter((v) => v.type === 'application/json');
    if (originFiles.length > 1) {
      dispatch(dialogAction.pushMessage({
        title: '確認',
        message: ['設定できるファイルは一つまでです。'],
        buttons: [
          { label: 'OK', callback: () => dispatch(dialogAction.pop())}
        ]
      }));
      return;
    }
    if (originFiles.length && !files.length) {
      dispatch(dialogAction.pushMessage({
        title: '確認',
        message: ['正しい形式のファイルをアップロードしてください。'],
        buttons: [
          { label: 'OK', callback: () => dispatch(dialogAction.pop())}
        ]
      }));
      return;
    }
    const reader = new FileReader();
    reader.onload = async() => {
      const text = reader.result as string;
      const isValid = await checkJson(text);
      console.log(isValid);
      if (isValid) {
        dispatch(dialogAction.pop());
        resolve(files[0]);
      } else {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: [
            'JSONファイルが破損しています。',
            '正しい形式のファイルをアップロードしてください。',
          ],
          buttons: [
            { label: 'OK', callback: () => dispatch(dialogAction.pop())}
          ],
        }));
      }
    }
    reader.readAsText(files[0]);
  });


  const onClickFile = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/json';
    input.onchange = async(e) => {
      if (isAdd) {
        IspLogOperation('authManagerAddDropFile');
      }
      if (input.files) {
        const checkFile = await validationJson(input.files);
        props.callback(checkFile);
      }
    }
    input.click();
    input.remove();
  }, [isAdd, props, validationJson]);

  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.dropEffect = 'copy';
    e.preventDefault();
    setDraggable(true);
  }, []);
  const onDragleave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggable(false);
  }, []);

  const onDrop = useCallback(async(e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggable(false);
    if (isAdd) {
      IspLogOperation('authManagerAddDropFile');
    }
    const checkFile = await validationJson(e.dataTransfer.files);
    props.callback(checkFile);

  }, [isAdd, props, validationJson]);


  return (
    <div
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragleave}
      className={`json_upload_dialog__body${draggable ? ' draggable' : '' }`}
    >
      <div
        onMouseEnter={e => e.stopPropagation()}
        onDragLeave={e => e.stopPropagation()}
      >jsonファイルをドラッグ＆ドロップしてください。
      </div>
      <div
        onMouseEnter={e => e.stopPropagation()}
        onDragLeave={e => e.stopPropagation()}
      >またはファイルを選択してください
      </div>
      <div>
        <Button label="ファイルを選択" onClick={onClickFile} onDragEnter={e => e.stopPropagation()} onDragLeave={e => e.stopPropagation()} />
      </div>
    </div>
  )
}
