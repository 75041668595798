import con from '../../../connection';
var path = '/password';
var password = function (url) { return function () { return ({
    // パスワードリセット
    reset: function (param) { return con.post(url + path + '/reset', param); },
    // パスワード再設定ポーリング
    polling: function (param, options) { return con.post(url + path + '/polling', param, options === null || options === void 0 ? void 0 : options.noError); },
    // パスワードリセットトークン認証
    auth: function (param) { return con.post(url + path + '/auth', param); },
    // パスワード再設定
    set: function (param) { return con.post(url + path + '/set', param); },
}); }; };
export default password;
