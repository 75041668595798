import con from '../../../connection';
import categories from './categories/index';
import levels from './levels/index';
import destinations from './destinations/index';
import templates from './templates/index';
var path = '/informations';
var informations = function (url) { return function (info_id) { return ({
    categories: categories(url + path),
    levels: levels(url + path),
    destinations: destinations(url + path, info_id),
    templates: templates(url + path),
    get: function (param) {
        return !info_id && param
            ? con.get(url + path, param)
            : con.get("" + url + path + "/" + info_id, {});
    },
    post: function (param) {
        return con.post(url + path + (info_id ? "/" + info_id : ''), param);
    },
    delete: function () {
        return con.delete("" + url + path + "/" + info_id, {});
    },
}); }; };
export default informations;
