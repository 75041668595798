import { members } from './members';
import con from '../../../connection';
import { organizations } from './organizations';
import portals from './portals/index';
import reuse from './reuse';
import { check } from './check/index';
import operation from './operation';
import authorities from './authorities';
var path = '/companies';
var companies = function (url) { return function (company_code) {
    return {
        check: check(url + path),
        members: members("" + url + path + "/" + company_code),
        organizations: organizations("" + url + path + "/" + company_code),
        portals: portals("" + url + path + "/" + company_code),
        reuse: reuse("" + url + path + "/" + company_code),
        operation: operation("" + url + path + "/" + company_code),
        authorities: authorities("" + url + path),
        get: function (param) {
            return !company_code && param
                ? con.get(url + path, param)
                : con.get("" + url + path + "/" + company_code, {});
        },
        post: function (param) {
            return con.post(url + path + (company_code ? "/" + company_code : ''), param);
        },
    };
}; };
export default companies;
