import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { Table } from '../../table/table';
import { TableElement } from '../../table/table-element';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { Button } from '../../ui/button/button';
import { Checkbox } from '../../ui/checkbox/checkbox';
import { DatePicker } from '../../ui/date-picker/date-picker';
import { Header } from '../../ui/header/header';
import { LabeledForm } from '../../ui/input/labeled-form';
import { ReactSelect, SelectInfoData } from '../../ui/select/react-select';
import { Select } from '../../ui/select/select';
import { SideBar } from '../../ui/sidebar/sidebar';
import './notification-settings.scss';
import iconSearch from '../../../assets/images/common/icon_search.svg';
import iconnBuilding from '../../../assets/images/common/icon_building.svg';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { push } from '@lagunovsky/redux-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { prefectureList } from '../../../models/prefecture-list';
import { apiAdmin, apiData, apiCompany } from '../../../managers/api-manager';
import { useDidMount } from '../../../hooks/life-cycle';
import { DateFormatter } from '../../../utilities/date-formatter';
import { NotificationDetaEntity, InfomationDetailEntity } from './type/infomation.type';
import { isEqual, cloneDeep } from 'lodash';
import { InfomationModel } from '../../../models/infomation/infomation-model';
import { dialogAction } from '../../../slices/dialog-slice';
import { informationAction } from '../../../slices/information-slice';
import { Input } from '../../ui/input/input';
import iconEdit from '../../../assets/images/common/icon_edit.svg';
import { useLocation } from 'react-router-dom';
import { OrganizationSelectDialogInformation } from '../../dialog/organization-select-dialog-information';
import { Organization } from '../../../models/edit-tree-view/models/organization';
import { useAutoSelectLogOperation } from '../../../hooks/use-async-log-operation';
import { ApiGetInformationsDestinationsResponse } from '../../../api/api/admin-web/informations/destinations/api-get-informations-destinations.response';

const roleList = [
  { value: '1', label: '管理' },
  { value: '2', label: '一般' },
  { value: '3', label: 'ゲスト' },
];

const appRoleList = [
  { value: '1', label: 'CADECT (基本)' },
  { value: '2', label: 'CADECT (省エネ)' },
  { value: '3', label: 'CADECT (空調)' },
  { value: '4', label: 'CADECT (防災)' },
];

const INIT_REQUEST_PARAM = {
  "is_cadect1": "1",
  "is_cadect2": "1",
  "is_cadect3": "1",
  "is_cadect4": "1",
  "roles[0]": "1",
  "roles[1]": "2",
  "roles[2]": "3",
}

const CsvUploader = (props: { callback: (file: File) => void }) => {
  const dispatch = useAppDispatch();
  const [draggable, setDraggable] = useState(false);
  const onClickFile = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (e) => {
      if (input.files) {
        const files = Array.from(input.files).filter((v) => /\.(csv)$/i.test(v.name));
        if (files.length) props.callback(files[0]);
      }
    }
    input.click();
    input.remove();
  }, []);

  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.dropEffect = 'copy';
    e.preventDefault();
    setDraggable(true);
  }, []);
  const onDragleave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggable(false);
  }, []);

  const onDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggable(false);
    const originFiles = Array.from(e.dataTransfer.files);
    const files = Array.from(e.dataTransfer.files).filter((v) => /\.(csv)$/i.test(v.name));
    if (originFiles.length > 1) {
      dispatch(dialogAction.pushMessage({
        title: '確認',
        message: ['設定できるファイルは一つまでです。'],
        buttons: [
          { label: 'OK', callback: () => dispatch(dialogAction.pop())}
        ]
      }));
      return;
    }
    if (originFiles.length && !files.length) {
      dispatch(dialogAction.pushMessage({
        title: '確認',
        message: ['正しい形式のファイルをアップロードしてください。'],
        buttons: [
          { label: 'OK', callback: () => dispatch(dialogAction.pop())}
        ]
      }));
      return;
    }
    props.callback(files[0]);
  }, []);

  const onClickTemplateDL = useCallback(() => {
    const csvData = 'email';
    const name = '通知先指定テンプレート.csv';
    const blob = new Blob([csvData], {type: 'text/csv'});
    const uri = URL.createObjectURL(blob);
    const elm = document.createElement('a');
    elm.download = name;
    elm.href = uri;

    document.body.appendChild(elm);
    elm.click();
    elm.remove();
    URL.revokeObjectURL(uri);
  }, []);

  return (
    <div
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragleave}
      className={`csv_upload_dialog__body${draggable ? ' draggable' : '' }`}
    >
      {/* TODO よしなかさん ちらつきについてご相談した箇所になります */}
      <div
        onMouseEnter={e => e.stopPropagation()}
        onDragLeave={e => e.stopPropagation()}
      >csvファイルをドラッグ＆ドロップしてください。
      </div>
      <div
        onMouseEnter={e => e.stopPropagation()}
        onDragLeave={e => e.stopPropagation()}
      >またはファイルを選択してください
      </div>
      <div>
        <Button label="ファイルを選択" onClick={onClickFile} onDragEnter={e => e.stopPropagation()} onDragLeave={e => e.stopPropagation()} />
        <Button label="テンプレートDL" onClick={onClickTemplateDL} onDragEnter={e => e.stopPropagation()} onDragLeave={e => e.stopPropagation()} />
      </div>
    </div>
  )
}

const SampleAddDialog = (props: {callback: (v: any) => void}) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const onclick = useCallback(async() => {
    props.callback({ name });
  }, [props.callback, name]);
  return (
    <>
      <div>テンプレート名を入力してください</div>
      <Input maxLength={20} onChange={(e) => setName(e.target.value)} />
      <footer>
        <Button label="キャンセル" onClick={() => dispatch(dialogAction.pop())} color="tertiary" size="large" />
        <Button label="追加" onClick={onclick} size="large" />
      </footer>
    </>
  )
}
const SampleEditDialog = (props: {name: string, callback: (v: any) => void, onDelete: () => void}) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState(props.name);
  const onclick = useCallback(async() => {
    props.callback({ name });
  }, [props.callback, name]);
  return (
    <>
      <div>新しいテンプレート名を入力してください</div>
      <div>
        <Input value={name} maxLength={20} onChange={(e) => setName(e.target.value)} />
        <Button label="更新" onClick={onclick} />
      </div>
      <footer>
        <Button label="キャンセル" onClick={() => dispatch(dialogAction.pop())} color="tertiary" size="large" />
        <Button label="テンプレートを削除" onClick={() => props.onDelete()} size="large" color="quaternary" />
      </footer>
    </>
  )
}

const check = (list: NotificationDetaEntity[], target: NotificationDetaEntity): NotificationDetaEntity[] => {
  if (list.find((v) => target.member_id === v.member_id && target.company_code === v.company_code)) {
    return list.filter((v) => !isEqual(v, target));
  } else {
    return [...list, {...target}];
  }
}

export const NotificationSettingsCompany = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;

  const dispatch = useAppDispatch()
  const { sendList } = useAppSelector((v) => v.information);
  const operation = useAutoSelectLogOperation(apiManger.type);
  const { companyUserInfo } = useAppSelector((v) => v.loginCompanyUserInfo);
  // ソートキーの命名
  const sortKey = {
    id: 1,
    category: 2,
    level: 3,
    title: 4,
    creatDate: 5,
    name: 6,
    status: 7,
    startDate: 8,
    endDate: 9,
  };
  // 昇降の明示
  const highlowKey = {
    high: 0,
    low: 1,
  };
  const dateRef = useRef<HTMLButtonElement>(null);
  const dateRef2 = useRef<HTMLInputElement>(null);
  // ”テンプレートから指定”ボタンref
  const specifyTempBtn = useRef<HTMLButtonElement>(null);

  const [sortBy, setSortBy] = useState(sortKey.creatDate);
  const [highlow, setHighlow] = useState(highlowKey.low);
  /* 都道府県 */
  const [prefecature, setPrefecture] = useState<SelectInfoData[]>([]);
  /* 企業 */
  const [companyList, setCompanyList] = useState<SelectInfoData[]>([]);
  const [company, setCompany] = useState<SelectInfoData[] | null>(null);
  /* 企業登録日 */
  const [registerDate, setRegisterDate] = useState('');
  /* ユーザー登録日 */
  const [registerUserDate, setRegisterUserDate] = useState('');
  /* 業種 */
  const [industrieList, setIndustrieList] = useState<SelectInfoData[]>([]);
  const [selectIndustries, setSelectIndustries] = useState<SelectInfoData[]>([]);
  /* 企業ユーザー名 */
  const [selectUsers, setSelectUsers] = useState<SelectInfoData[]>([]);
  const [userList, setUserList] = useState<SelectInfoData[]>([]);
  /* 職種 */
  const [selectOccupations, setSelectOccupations] = useState<SelectInfoData[]>([]);
  const [occupationsList, setOccupationsList] = useState<SelectInfoData[]>([]);
  // 詳細検索の開閉状態
  /* ユーザー権限 */
  const [selectRole, setSelectRole] = useState<SelectInfoData[]>([]);
  /* アプリケーション権限 */
  const [selectAppRole, setSelectAppRole] = useState<SelectInfoData[]>([]);
  /* 詳細検索開閉フラグ */
  const [detailSearch, setDetailSearch] = useState(false);

  /* テーブル用情報リスト */
  const [_notificationList, setNotificationList] = useState<NotificationDetaEntity[]>([]);
  const [subjectList, setSubjectList] = useState<NotificationDetaEntity[]>([]);

  /* チェックリスト(ID格納) */
  const [notificationChecks, setNotificationChecks] = useState<NotificationDetaEntity[]>([]);
  const [subjectChecks, setSubjectChecks] = useState<NotificationDetaEntity[]>([]);

  /* キャンセル時用通知リスト */
  const [memoryList, setMemoryList] = useState<NotificationDetaEntity[]>([]);

  const [isAutoSearch, setIsAutoSearch] = useState(true);
  const [isCsvSearch, setIsCsvSearch] = useState(false);

  /* 「テンプレートから指定」押下時のリスト表示フラグ */
  const [isTemplateList, setIsTemplateList] = useState(false);
  const [templateInfoList, setTemplateInfoList] = useState<any[]>([]);
  const [templateInfo, setTemplateInfo] = useState<{id: string, name: string} | null>(null);

  /* 組織図 */
  const [organizationList, setOrganizationList] = useState<Organization[]>([]);
  const [selectOrganization, setSelectOrganization] = useState<{id: string, label: string}[]>([]);

  /* インフォメーションデータ */
  const [infoData, setInfoData] = useState<InfomationDetailEntity | null>(null);

  /* ”テンプレートから指定”ボタンref */
  const [specifyTempBtnWidth, setSpecifyTempBtnWidth] = useState<number>();
  
  const location = useLocation();
  /* - Memo - */
  const mode = useMemo(() => new URLSearchParams(location.search).get('m'), [location]);
  const id = useMemo(() => new URLSearchParams(location.search).get('i'), [location]);

  const notificationList = useMemo(() => {
    const list = _notificationList.filter((v) => selectUsers.length ? !!selectUsers.find((v2) => v.member_id === v2.value.split('/')[0] && v.company_code === v2.value.split('/')[1]) : true);
    const filterList = list.filter((v) => !subjectList.find((v2) => v2.company_code === v.company_code && v2.member_id === v.member_id));
    setNotificationChecks((prev) => {
      return prev.filter((v) => filterList.find((v2) => v2.member_id === v.member_id && v2.company_code === v.company_code))
    });
    return filterList;
  }, [_notificationList, subjectList, selectUsers])
  const tableHead1 = useMemo(() => {
    const allCheck = () => {
      const list: boolean[] = [];
      notificationList.forEach((v) => {
        const find = notificationChecks.find((v2) => v2.company_code === v.company_code && v2.member_id === v.member_id);
        list.push(!!find);
      });
      if (!list.length) return false;
      return list.every((v) => v);
    }
    return [
    <Checkbox
      label="選択"
      id="selectAllTop"
      checked={allCheck()}
      disabled={!notificationList.length}
      onClick={() => {
        if (!notificationList.length) return;
        if (subjectChecks.length) setSubjectChecks([]);
        setNotificationChecks(
          !allCheck()
          ? cloneDeep(notificationList)
          : []);
      }}
    />,
    '企業名',
    '企業ユーザ名',
    'メールアドレス',
  ]}, [notificationChecks, notificationList, subjectChecks]);
  const tableHead2 =  useMemo(() => {
    const allCheck = () => {
      const list: boolean[] = [];
      subjectList.forEach((v) => {
        const find = subjectChecks.find((v2) => v2.company_code === v.company_code && v2.member_id === v.member_id);
        list.push(!!find);
      });
      if (!list.length) return false;
      return list.every((v) => v);
    }
    return [
      <Checkbox
        label="選択"
        id="selectAllBottom"
        disabled={!subjectList.length}
        checked={allCheck()}
        onClick={() => {
          if (!subjectList.length) return;
          if (notificationChecks.length) setNotificationChecks([]);
          setSubjectChecks(
          !allCheck()
          ? cloneDeep(subjectList)
          : []
        )}}
      />,
      '企業名',
      '企業ユーザ名',
      'メールアドレス',
    ];

  }, [subjectChecks, subjectList, notificationChecks])

  const tableBody1: TableElement[][] = useMemo(() => {
    return notificationList.map((v, i) => [
     <Checkbox
      label=""
      id="select1_index"
      checked={!!notificationChecks.find((v2) => v.member_id === v2.member_id && v.company_code === v2.company_code)}
      onClick={(e) => e.stopPropagation()}
     />, v.company_name, v.display_name, v.email])
  }, [notificationList, notificationChecks, subjectChecks]);
  const tableBody2: TableElement[][] = useMemo(() => {
    return subjectList.map((v) => [
      <Checkbox
        label=""
        checked={!!subjectChecks.find((v2) => v.member_id === v2.member_id && v.company_code === v2.company_code)}
        id="select2_index"
        onClick={(e) => e.stopPropagation()}
      />, v.company_name, v.display_name, v.email])
    }, [subjectList, subjectChecks, notificationChecks]);

    const getNotificationUsers = useCallback(async(param: any) => {
      const list = await apiCompany.informations().destinations().get(param) as ApiGetInformationsDestinationsResponse;
      setNotificationList(list.body.data);
    }, []);

    const createRequestParam = useCallback((param: {
      selectIndustries: typeof selectIndustries,
      selectRole: typeof selectRole,
      selectAppRole: typeof selectAppRole,
      selectOccupations: typeof selectOccupations,
      registerUserDate: typeof registerUserDate
      selectOrganization: typeof selectOrganization,
    }) => {
      return (
        InfomationModel.listParam({
          member_created_at_from: param.registerUserDate,
          organization_codes: param.selectOrganization.map((v) => ({value: v.id, label: v.label})),
          industry_ids: param.selectIndustries,
          roles: param.selectRole,
          occupation_ids: param.selectOccupations,
          is_cadect1: param.selectAppRole.find((v) => v.value === '1') ? '1' : undefined,
          is_cadect2: param.selectAppRole.find((v) => v.value === '2') ? '1' : undefined,
          is_cadect3: param.selectAppRole.find((v) => v.value === '3') ? '1' : undefined,
          is_cadect4: param.selectAppRole.find((v) => v.value === '4') ? '1' : undefined,
        })
      )
    }, [prefecature, company, registerDate, selectIndustries, selectRole, selectAppRole, selectOccupations, registerUserDate, selectOrganization])
    const requestParam = useMemo(() => {
      return createRequestParam({
        selectIndustries,
        selectRole,
        selectAppRole,
        selectOccupations,
        registerUserDate,
        selectOrganization: selectOrganization.map((v) => ({id: v.id, label: v.id})),
      });
    }, [prefecature, company, registerDate, selectIndustries, selectRole, selectAppRole, selectOccupations, registerUserDate, selectOrganization, createRequestParam])
    const templateList = useMemo(()=>{
      const arr = ["設計事務所 - 東京・神奈川", "設計事務所 - 九州", "施工会社"];
      return arr;
    },[]);

    const isNoConditions = useMemo(() => {
      const values = Object.values(requestParam);
      return values.every((v) => Array.isArray(v) ? !v.length : !v) && !selectUsers.length;
    }, [requestParam, selectUsers])

    /* パンくずリスト */
    const breadcrumbList = [
      breadcrumbParts.company.home,
      breadcrumbParts.company.info,
      (() => {
        if (mode === 'add') {
          return breadcrumbParts.company.infoAdd
        } else if (mode === 'clone') {
          return {...breadcrumbParts.company.infoAdd, route: RoutingPath.companyInfoClone.replace(':infoId', id ?? '')}
        } else {
          return {...breadcrumbParts.company.infoDetail, route: RoutingPath.companyInfoDetail.replace(':infoId', id ?? '')}
        }
      })(),
      breadcrumbParts.company.infoSelect,
    ];    

  // - callback -
  const onclickCansel = useCallback(() => {
    if (mode === 'edit') {
      dispatch(push(`${RoutingPath.companyInfoDetail.replace(':infoId', id ?? '')}`));
      return;
    }
    const ispUrl = `${RoutingPath[mode === 'add' ? 'adminInfoAdd' : `adminInfoClone`]}`;
    const companyUrl = `${RoutingPath[mode === 'add' ? 'companyInfoAdd' : 'companyInfoClone']}`

    dispatch(informationAction.setSendList(cloneDeep(memoryList)))
    apiManger.type === 'company' ?
      CompanyLogOperation('informationCreateClick', () => {
        dispatch(push(mode === 'add' ? companyUrl : companyUrl.replace(':infoId', id ?? '')));
      }) :
      IspLogOperation('informationCreateClick', () => {
        dispatch(push(mode === 'add' ? ispUrl : ispUrl.replace(':infoId', id ?? '')));
      });
  }, [memoryList]);
  const onChangeRegisterUserDate = (v: Date | null) => {
    setRegisterUserDate(v ? DateFormatter.date2str(v, 'YYYYMMDD', '-') : '');
  }
  const getUser = useCallback(async() => {
    const list = await apiCompany.members().get({});
    setUserList((list.body.data as any).map((v: any) => ({ value: `${v.member_id}/${v.company_code}`, label: v.display_name })));
    // console.log(list.body.data);
  }, []);

  const getIndustries = useCallback(async() => {
    const list = await apiCompany.common().industries(1).get();
    setIndustrieList(list.body.data.map((v) => ({value: `${v.id}`, label: v.name})));
  }, []);

  const getOccupations = useCallback(async() => {
    const list = await apiCompany.common().occupations().get;
    setOccupationsList(list.body.data.map((v: any) => ({ value: v.id, label: v.name })));
  }, []);

  const onClickNotificationTd = useCallback((i: number) => {
    if (!notificationList[i]) return;
    if (subjectChecks.length) setSubjectChecks([]);
    setNotificationChecks(check(notificationChecks, notificationList[i]));
  }, [notificationChecks, notificationList, subjectChecks])

  const onClickSubjectTd = useCallback((i: number) => {
    if (!subjectList[i]) return;
    if (notificationChecks.length) setNotificationChecks([]);
    setSubjectChecks(check(subjectChecks, subjectList[i]));
  }, [subjectChecks, subjectList, notificationChecks])

  const insertList = useCallback(() => {
    setSubjectList([...subjectList, ...cloneDeep(notificationChecks)]);
    setNotificationChecks([]);
  }, [notificationChecks, subjectList]);

  const outList = useCallback(() => {
    const filter = subjectList.filter((v) => {
      return !subjectChecks.find((v2) => v2.company_code === v.company_code && v2.member_id === v.member_id);
    });
    setSubjectList(cloneDeep(filter));
  }, [subjectChecks, subjectList, ]);

  const getTemplateList = useCallback(async() => {
    const list =  await apiCompany.informations().templates().get();
    setTemplateInfoList(list.body.data);
  }, []);


  const saveTemplate = useCallback(() => {
    dispatch(dialogAction.push({
      title: 'テンプレート追加',
      element: <SampleAddDialog callback={(v) => {
        apiCompany.informations().templates().post({
          name: v.name,
          names: selectUsers.length ? selectUsers.map((v) => JSON.stringify(v)) : undefined,
          organization_codes: selectOrganization.length ? selectOrganization.map((v) => v.id) : undefined,
          ...requestParam,
        }).then(() => {
          dispatch(dialogAction.pop());
          getTemplateList();
        });
      }} />,
      className: "sample_add_dialog"
    }))
  }, [requestParam, selectUsers, getTemplateList]);

  const editTemplate = useCallback(async(id: string, name: string, isResave?: boolean) => {
    const isResaveDialog = (): Promise<boolean> => new Promise<boolean>((resolve) => {
      dispatch(dialogAction.pushMessage({
        title: '確認',
        message: [`テンプレート ${name}に、現在の検索条件を上書きしますか？`],
        buttons: [
          {label: 'OK', callback: () => resolve(true)},
          {label: 'キャンセル', callback: () => resolve(false)},
        ],
      }))
    });

    const postEditTemplate = async(_name: string) => {
      await apiCompany.informations().templates(id).name().post({ name: _name })
      apiCompany.informations().templates(id).post({
        name: _name,
        names: selectUsers.length ? selectUsers.map((v) => JSON.stringify(v)) : undefined,
        organization_codes: selectOrganization.length ? selectOrganization.map((v) => v.id) : undefined,
        ...requestParam,
      })
    }
    if (isResave) {
      await operation('informationNotificationOverwritingSaveClick');
      const isAccept = await isResaveDialog();
      if (isAccept) await postEditTemplate(name);
      dispatch(dialogAction.pop());
      return;
    }
    setIsTemplateList(false);
    dispatch(dialogAction.push({
      title: 'テンプレート編集',
      element: <SampleEditDialog 
        name={name}
        callback={async(v) => {
          await operation('informationNotificationEditUpdateClick');
          postEditTemplate(v.name).then(() => {
             dispatch(dialogAction.pop());
             getTemplateList();
           });
        }}
        onDelete={async() => {
          await operation('informationNotificationEditDeleteClick');
          dispatch(dialogAction.pushMessage({
            title: '確認',
            message: [`${name} を削除します。よろしいですか？`],
            buttons: [
              { label: 'キャンセル', callback: () => dispatch(dialogAction.pop())},
              { label: '削除', callback: async() => {
                await apiCompany.informations().templates(id).delete();
                getTemplateList();
                dispatch(dialogAction.pushMessage({
                  title: '確認',
                  message: ['削除が完了しました。'],
                  buttons: [
                    { label: 'OK', callback: () =>  dispatch(dialogAction.popAll())}
                  ]
                }))
              }},
            ]
          }))
        }}
      />,
      className: "sample_edit_dialog"
    }))
  }, [requestParam, selectUsers, getTemplateList]);

  const setTemplate = useCallback((param: any) => {
    setIsAutoSearch(false);
    setRegisterDate(param.created_at_from ?? '');
    setRegisterUserDate(param.member_created_at_from ?? '');
    const industries = industrieList?.filter((v) => param.industry_ids?.includes(v.value)) ?? [];
    setSelectIndustries(param.industry_ids ? industries : []);
    const companys = companyList.filter((v) => param.company_codes?.includes(v.value)) ?? [];
    setCompany(companys);
    setSelectUsers(param.names?.length ? param.names : []);
    const prefectures = prefectureList.filter((v) => param.pref_ids?.includes(v.key)) ?? [];
    setPrefecture(prefectures.map((v) => ({ value: v.key, label: v.label})));
    const roles = roleList?.filter((v) => param.roles?.includes(v.value)) ?? [];
    setSelectRole(roles);
    const organizations = organizationList.filter((v) => param.organization_codes?.includes(v.organization_code));
    setSelectOrganization(organizations.map((v) => ({ id: v.organization_code, label: v.organization_name})));
    const appRoles = appRoleList.filter((v) => {
      if (v.value === '1' && param.is_cadect1 === '1') return true;
      if (v.value === '2' && param.is_cadect2 === '1') return true;
      if (v.value === '3' && param.is_cadect3 === '1') return true;
      if (v.value === '4' && param.is_cadect4 === '1') return true;
      return false;
    });
    const occupations = occupationsList.filter((v) => param.occupation_ids?.includes(String(v.value))) ?? [];
    setSelectOccupations(occupations);
    setSelectAppRole(appRoles);
    const _param = createRequestParam({
      selectIndustries: param.industry_ids ? industries : [],
      selectRole: roles,
      selectAppRole: appRoles,
      selectOccupations: occupations,
      registerUserDate: param.member_created_at_from ?? '',
      selectOrganization: [],
    })
    getNotificationUsers(_param).then(() => setIsAutoSearch(true));
  }, [industrieList, companyList, prefectureList, appRoleList, occupationsList, selectOrganization, createRequestParam, organizationList]);

  const getTemptes = useCallback(async(id: string, name: string) => {
    setTemplateInfo({ id, name })
    const list = await apiCompany.informations().templates(id).get();
    const body = list.body.data as any;
    const names = `[${body.names ?? ''}]`;
    const datas = {...body, names: JSON.parse(names)};
    setTemplate(datas);
  }, [setTemplate]);

  const onClickCsvUpload = useCallback(async() => {
    await operation('informationNotificationCsvImportClick');
    const warning = (): Promise<boolean> => new Promise((resolve) => {
      if ((!isEqual(INIT_REQUEST_PARAM, requestParam) || isCsvSearch) && notificationList.length) {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['対象ユーザーがクリアされますが、よろしいですか？'],
          buttons: [
            {
              label: 'キャンセル',
              callback: () => {
                resolve(true);
                dispatch(dialogAction.pop());
              },
              color: "tertiary",
            },
            {label: 'クリアして再選択', callback: () => {
              resolve(false);
              dispatch(dialogAction.pop());
            }},
          ]
        }))
      } else {
        resolve(false);
      }
    });
    const isBack = await warning();
    if (isBack) return;
    dispatch(dialogAction.push({
      title: '',
      outClickPop: true,
      element: <CsvUploader callback={(v) => {
        apiCompany.informations().destinations().upload().post({
          File: v,
        }).then((res) => {
          setIsCsvSearch(true);
          const data = res.body.data;
          setNotificationList(cloneDeep(data as any));
          dispatch(dialogAction.pop());
        }).catch(() => {
          dispatch(dialogAction.pushMessage({
            title: '確認',
            message: ['既存ユーザー情報と合致しないデータがあります。'],
            buttons: [{ label: 'OK', callback: () => dispatch(dialogAction.pop()) }],
          }));
        })
      }} />,
      className: "csv_upload_dialog",
    }))
  }, [requestParam, isCsvSearch, userList, notificationList]);
  const onClickRegister = useCallback(async() => {
    if (mode === 'edit' && infoData) {
      apiCompany.informations(id!).post({
        ...infoData,
        sender_list: subjectList as any,
      }).then(() => {
        dispatch(dialogAction.pushMessage({
          title: '確認',
          message: ['更新が完了しました。'],
          buttons: [
            { label: 'OK', callback: () => {
              dispatch(dialogAction.pop());
              dispatch(push(RoutingPath.companyInfoDetail.replace(':infoId', id ?? '')));
            }},
          ],
        }));
      });
      return;
    }
    await operation('informationNotificationUserSelect');
    dispatch(informationAction.setSendList(cloneDeep(subjectList)));
    const url = `${RoutingPath[mode === 'add' ? 'companyInfoAdd' : 'companyInfoClone']}`;

    dispatch(push(mode === 'add' ? url : url.replace(':infoId', id ?? '')));
  }, [subjectList, infoData, id]);

  const openOrganization = useCallback(() => {
    dispatch(dialogAction.push({
      title: '組織の指定',
      element: 
        <OrganizationSelectDialogInformation 
           callback={(v) => {
             setSelectOrganization(v.map((v) => ({id: v.id, label: v.label})));
            }}
           orgData={organizationList} 
           selectList={selectOrganization}
        />
    }))
  }, [organizationList, selectOrganization]);

  // - Effect -
  useEffect(() => {
    if (isAutoSearch) {
      getNotificationUsers(requestParam);
      setIsCsvSearch(false);
    }
  }, [requestParam])
  useEffect(() => {
    if (!mode || ((mode === 'clone' || mode === 'edit') && !id)) dispatch(push(RoutingPath[apiManger.type === 'admin' ? 'adminInfoAdd' : 'companyInfoAdd']));
    if (mode === 'edit' && id) {
      apiCompany.informations(id).get().then((v) => {
        setInfoData((v.body.data as any).data);
      })
    }
  }, [mode])
  // リサイズ時処理
  const resize = () => {
    specifyTempBtn.current && setSpecifyTempBtnWidth(specifyTempBtn.current?.getBoundingClientRect().width);
  }
  useEffect(() => {
    resize();
    }, [specifyTempBtn.current]);
  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  const getOrganization = useCallback(async() => {
    const list = await apiCompany.organizations().get();
    setOrganizationList(list.body.data as any);
  }, []);

  useDidMount(() => {
    setSubjectList(cloneDeep(sendList));
    setMemoryList(cloneDeep(sendList));
    dispatch(informationAction.setSendList([]));
    getUser();
    getIndustries();
    getOccupations();
    getOrganization();
    // getBuildingType();
    // getNotificationUsers();
  })

  return (
    <div
      id="App"
      className="notification_settings"
      onClick={() => setIsTemplateList(false)}
    >
      <SideBar currentPage="information" apiManger={apiManger.type} />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList} />
          <div className="inner">
            <section className="">
              <header>
                <h2>通知先設定</h2>
                <Button
                  size="large"
                  label="CSV取り込み"
                  onClick={onClickCsvUpload}
                />
              </header>
              <div className="notification_settings__body">
                <div className="notification_settings__search">
                  <div className="notification_settings__search__header"><img alt="" src={iconSearch} />検索条件</div>
                  <div className="notification_settings__search__body">
                    <div className="notification_settings__search__forms">
                      <LabeledForm
                        label="組織名"
                        formEle={
                          <ReactSelect 
                            inputHidden
                            isMulti
                            menuIsOpen={false}
                            formMode="editable"
                            editableSrc={iconnBuilding}
                            handleChange={(v) => {
                              if (!v || (!v as any)?.length === 0) {
                                setSelectOrganization([]);
                              } else {
                                const result: {id: string, label: string}[] = [];
                                selectOrganization.forEach((v2) => {
                                  const find = (v as any).find((v3: any) => v3.value === v2.id);
                                  if (find) result.push(({ id: find.value, label: find.label}));
                                });
                                setSelectOrganization(result);
                              }
                            }}
                            select={selectOrganization.map((v) => ({value: String(v.id), label: String(v.label)}))}
                            onClickEditable={() => {
                              openOrganization()
                            }}
                            list={organizationList.map((v) => ({value: String(v.organization_code), label: String(v.organization_name) }))}
                          />
                        }
                        labelSide="top"
                        className='alt_select'
                      />
                      {/* <LabeledForm
                        label="組織図"
                        formEle={<>{selectOrganization.map((v, i) =>  {console.log(v); return <Input key={i} value={v.label} disabled />})}</>}
                        labelSide="top"
                      />
                      <Button label={'選択'} onClick={openOrganization}/> */}
                      <LabeledForm
                        label="企業ユーザー名"
                        formEle={<ReactSelect
                          list={userList}
                          select={selectUsers}
                          handleChange={(v) => {
                            setSelectUsers(v as SelectInfoData[]);
                          }}
                          formMode="pulldown"
                          isMulti
                        />}
                        labelSide="top"
                      />
                      <LabeledForm
                        label="職種"
                        formEle={<ReactSelect
                          list={occupationsList}
                          select={selectOccupations}
                          handleChange={(v) => {
                            setSelectOccupations(v as SelectInfoData[]);
                          }}
                          inputHidden
                          formMode="pulldown"
                          isMulti
                        />}
                        labelSide="top"
                        className='alt_select'
                      />
                    </div>
                    <div className="notification_settings__search__forms">
                      <div
                        className={`notification_settings__search__forms__toggle${detailSearch ? '' : ' close'}`}
                        onClick={() => setDetailSearch(!detailSearch)}
                      >
                        詳細検索<span className="up_down_toggle" />
                      </div>
                      {detailSearch &&
                        <>
                          <LabeledForm
                            label="ユーザー登録日"
                            formEle={
                              <Input
                                type="date"
                                value={registerUserDate || ''}
                                overErrorIgnore
                                onClickClearDate={() => onChangeRegisterUserDate(null)}
                                onChange={(e) => onChangeRegisterUserDate(e.target.value ? new Date(e.target.value) : null)}
                                ref={dateRef2}
                              />          
                            }
                            labelSide="top"
                          />
                          <LabeledForm
                            label="ユーザー権限"
                            formEle={<ReactSelect
                              list={roleList}
                              select={selectRole}
                              inputHidden
                              handleChange={(v) => {
                                setSelectRole(v as SelectInfoData[]);
                              }}
                              isMulti
                              formMode="pulldown"
                            />}
                            labelSide="top"
                          />
                          <LabeledForm
                            label="アプリ権限"
                            formEle={<ReactSelect
                              list={appRoleList}
                              select={selectAppRole}
                              handleChange={(v) => {
                                setSelectAppRole(v as SelectInfoData[]);
                              }}
                              inputHidden
                              isMulti
                              formMode="pulldown"
                            />}
                            labelSide="top"
                          />
                          {/* <LabeledForm
                            label="建物用途"
                            formEle={<ReactSelect
                              list={[]}
                              select={[]}
                              changeLabelList={[]}
                              handleChange={(v) => {
                              }}
                              formMode="pulldown"
                            />}
                            labelSide="top"
                          /> */}
                        </>
                      }
                    </div>
                  </div>
                  <div className="notification_settings__search__bottom">
                    <div className="specify_template_button" >
                      <Button
                        label="テンプレートから指定"
                        onClick={async(e) => {
                          await operation('informationNotificationTemplateClick');
                          e.preventDefault();
                          e.stopPropagation()
                          setIsTemplateList(!isTemplateList);
                          getTemplateList();
                        }}
                        ref={specifyTempBtn}
                      />
                      {/* {isTemplateList && */}
                        <ul 
                          className={`specify_template_button__list${isTemplateList ? '' : ' hidden' }`}
                          style={{ ...specifyTempBtnWidth ? { width: specifyTempBtnWidth } : {} }}
                        >
                          {templateInfoList.map((v, i)=>{
                            return (
                              <li
                                key={`specify-template-item_${i}`}
                                className="specify_template_button__list__item"
                              >
                                <button onClick={async(e)=>{
                                  e.preventDefault();
                                  await operation('informationNotificationTemplateSelect');
                                  getTemptes(v.id, v.name);
                                }} title={v.name}>{v.name}</button>
                                <button onClick={async(e)=>{
                                  e.preventDefault();
                                  if (isNoConditions) {
                                    e.stopPropagation();
                                    return
                                  };
                                  await operation('informationNotificationEditClick');
                                  editTemplate(v.id, v.name);
                                  setIsTemplateList(false);
                                }
                                }><img src={iconEdit} /></button>
                              </li>
                            );
                          })}
                        </ul>
                      {/* } */}
                    </div>
                    <div>
                      <Button
                        label="新規保存"
                        onClick={() => saveTemplate()}
                        disabled={isNoConditions}
                      />
                      <Button
                        label="上書き保存"
                        disabled={!templateInfo || isNoConditions || !templateInfoList.length}
                        onClick={() => editTemplate(templateInfo!.id, templateInfo!.name, true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="notification_settings__users">
                  <section>
                    <header>
                      <h2>対象ユーザー一覧</h2>
                      <div className="count">
                        <span>件数</span>
                        {notificationList.length}
                        <span>件</span>
                      </div>
                    </header>
                    <div className="notification_settings__users__table">
                      <Table
                        head={tableHead1}
                        body={tableBody1}
                        onClickRow={onClickNotificationTd}
                        alignList={['c', 'c', 'c', 'c']}
                        setSortBy={setSortBy}
                        setHighlow={setHighlow}
                      />
                    </div>
                  </section>
                  <div className="notification_settings__users__transfer" style={{zIndex: 1}}>
                    <button className="up_down_toggle close" disabled={!!subjectChecks.length} onClick={insertList} />
                    <button className="up_down_toggle" disabled={!!notificationChecks.length} onClick={outList} />
                  </div>
                  <section>
                    <header>
                      <h2>通知先ユーザー一覧</h2>
                      <div className="count">
                        <span>件数</span>
                        {subjectList.length}
                        <span>件</span>
                      </div>
                    </header>
                    <div className="notification_settings__users__table">
                      <Table
                        head={tableHead2}
                        body={tableBody2}
                        onClickRow={onClickSubjectTd}
                        alignList={['c', 'c', 'c', 'c']}
                        setSortBy={setSortBy}
                        setHighlow={setHighlow}
                      />
                    </div>
                  </section>
                </div>
              </div>
            </section>
            <footer className="btn_box align_center">
              <Button
                size="large"
                color="tertiary"
                label="キャンセル"
                onClick={onclickCansel}
              />
              <Button
                size="large"
                label="登録"
                onClick={onClickRegister}
                // disabled={isDisabled}
              />
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};