import con from '../../../../connection';
import files from './files';
import requests from './requests';
var path = '/plans';
var plans = function (url) { return function (plan_id) { return ({
    files: files("" + url + path + "/" + (plan_id || '')),
    requests: requests("" + url + path + "/" + (plan_id || '')),
    get: function (param) { return plan_id ? (
    // プラン詳細取得
    con.get("" + url + path + "/" + plan_id, {})) : (
    // プラン一覧取得
    con.get("" + url + path, param || {})); },
    post: function (param) { return plan_id ? (
    // プラン更新
    con.post("" + url + path + "/" + plan_id, param)) : (
    // プラン新規作成
    con.post("" + url + path, param)); },
    // プラン削除
    delete: function () { return con.delete("" + url + path + "/" + plan_id, {}); },
    // プラン復活
    restore: function () { return con.post("" + url + path + "/" + plan_id + "/restore", {}); },
    // プランコピー
    copy: function (param, noError, timeoutMS) { return con.post("" + url + path + "/" + plan_id + "/copy", param, noError, timeoutMS); },
}); }; };
export default plans;
