import con from '../../../connection';
import send from './send';
var path = '/requests';
var requests = function (url) { return function (request_id) { return ({
    get: function (param) { return request_id
        // 依頼詳細取得
        ? con.get(url + path + "/" + request_id, {})
        // 依頼一覧取得
        : con.get(url + path, param || {}); },
    send: send("" + url + path),
}); }; };
export default requests;
