import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { Button } from '../../ui/button/button';
import { SortListType, Table } from '../../table/table';
import { TableElement } from '../../table/table-element';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { Pager } from '../../ui/pager/pager';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { DateFormatter } from '../../../utilities';
import { apiCompany, ApiManager } from '../../../managers/api-manager';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { ArticleBodyType, ArticleType } from '../../../models/article-data.';
import { dialogAction } from '../../../slices/dialog-slice';
import { OrganizationSelectDialog } from '../../dialog/organization-select-dialog';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { ApiGetOrganizationsResponse } from '../../../api/api/admin-web/companies/organizations/api-get-organizations.response';
import { ApiGetCompanyArticlesResponse } from '../../../api/api/company-web/articles/api-get-company-articles-response';

const breadcrumbList = [
  breadcrumbParts.company.home,
  breadcrumbParts.company.articles,
];

export const Articles = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const [articles, setArticles] = useState<ArticleType[]>([]);
  const dispatch = useAppDispatch();
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);

  const [companyBody, setCompanyBody] = useState<ArticleBodyType>({
    hit_count: 0,
    data: {
      current_page: 0,
      data: null,
      first_page_url: '',
      from: 0,
      last_page: 0,
      last_page_url: '',
      links: null,
      next_page_url: '',
      path: '',
      per_page: 0,
      prev_page_url: '',
      to: 0,
      total: 0,
    },
  });

  // 表示データの全件数
  const totalData = companyBody.data.total;
  // 一度に表示するデータの件数
  const perData = companyBody.data.per_page;
  // 現在のページ
  const [currentPage, setCurrentPage] = useState(companyBody.data.current_page);
  const [queryFlag, setQueryFlag] = useState(true);
  // 表示開始のデータ番号
  const totalPage: number = useMemo(() => {
     // 合計のページ数(件数が丁度ページいっぱいで収まる時)
    return totalData % perData ?
      Math.floor(totalData / perData) : // 合計のページ数
      Math.floor(totalData / perData) - 1;
  }, [totalData, perData]);
  // ソートキーの命名
  const sortKey = {
    number: 1,
    name: 2,
    createdAt: 3,
    updatedUser: 5,
    createdUser: 12,
    org: 13,
  };
  // 昇降の明示
  const highlowKey = {
    high: 0,
    low: 1,
  };

  const [sortBy, setSortBy] = useState(sortKey.number);
  const [highlow, setHighlow] = useState(highlowKey.low);
  const [index, setIndex] = useState(0);
  const [activeSort, setActiveSort] = useState('active');
  const [updateFlag, setUpdateFlag] = useState(false);

  /** 物件付け替え */
  const handleClickExchange = useCallback((id: string) => {
    CompanyLogOperation('articleManagerMoveClick', () => {
      apiCompany.organizations().get()
      .then((res) => {
        CompanyLogOperation('articleManagerMoveOrgList', () => {
          const orgList = (res as ApiGetOrganizationsResponse).body.data;
          dispatch(dialogAction.push({
            title: '移動先の組織を選択して下さい',
            element: <OrganizationSelectDialog
              orgData={orgList as any}
              selectOrg={''}
              callback={(res) => {
                CompanyLogOperation('articleManagerMoveOrgListActivate', () => {
                  dispatch(dialogAction.pushMessage({
                    title: '確認',
                    message: [`${res.label}に物件を移動させます。`],
                    buttons: [
                      {
                      label: 'キャンセル',
                      callback: () => {
                        CompanyLogOperation('articleManagerMoveCancel', () => {
                          dispatch(dialogAction.pop());
                        })
                      },
                      color: 'tertiary',
                      },
                      {
                      label: '登録',
                      callback: () => {
                        CompanyLogOperation('articleManagerMoveRegister', () => {
                          dispatch(dialogAction.popAll());
                          apiCompany.articles(id).move().post({
                            organization_code: res.id
                          }).then(() => {
                            setUpdateFlag(prev => !prev);
                          }).catch((e) => {
                            ApiManager.errorFunc(e);
                          })
                        })
                      },
                      color: 'primary',
                      },
                    ],
                  }))
                })
              }}
              mode={'edit'}
              isCanselPop={true}
              isConfirm={true}
            />,
          }));
        })
      });
    })
  }, []);

  /** 表示するページをセット */
  const handlePaginate = useCallback((page: number) => {
    setCurrentPage(page * perData);
  }, [currentPage]);
  /** 企業一覧取得 */
  useEffect(() => {
    let unmounted = false;
    if (!companyUserInfo) {
      return;
    }
    if (queryFlag) {
      const query = QueryParamFormatter.queryParse().p;
      setCurrentPage(query ? Number(query) : currentPage);
    } else {
      setCurrentPage(currentPage);
    }
    (apiCompany.articles().get({
      sort_by: sortBy,
      highlow: highlow,
      limit: 20,
      page: currentPage + 1,
    }) as Promise<ApiGetCompanyArticlesResponse>)
      .then((v) => {
        if (!unmounted) {
          const body = v.body.data;
          setArticles(body.data ?? []);
          setCompanyBody(v.body);
          if (body.current_page > body.last_page) {
            setCurrentPage(body.last_page - 1);
          }
        }
      });
    setQueryFlag(false);
    return () => {
      unmounted = true;
    };
  }, [sortBy, highlow, currentPage, updateFlag]);
  /** ソート情報まとめ */
  const sortList: SortListType[] = useMemo(() => {
  return [
    {
      index: 0, // 何列目
      type: 'up', // 初期の昇降順
      key: sortKey.number, // 何でソートするか
      active: '', // 現在のソートならactive
      callback: () => {
      },
    },
    {
      index: 1,
      type: 'up',
      key: sortKey.name,
      active: '',
      callback: () => {
      },
    },
    {
      index: 2,
      type: 'up',
      key: sortKey.createdAt,
      active: '',
      callback: () => {
      },
    },
    {
      index: 3,
      type: 'up',
      key: sortKey.updatedUser,
      active: '',
      callback: () => {
      },
    },
    {
      index: 4,
      type: 'up',
      key: sortKey.createdUser,
      active: '',
      callback: () => {
      },
    },
    {
      index: 5,
      type: 'up',
      key: sortKey.org,
      active: '',
      callback: () => {
      },
    },
  ]
  }, []);
  // クリックされたソートを書き換える
  useEffect(() => {
    sortList.forEach(elm => {
      if (elm.index === index) {
        elm.type = highlow ? 'down' : 'up';
        elm.active = activeSort ? 'active' : '';
      } else {
        elm.type = 'up';
        elm.active = '';
      }
    });
  }, [articles]);

  // テーブル要素用意
  const head: string[] = [
    '物件No',
    '物件名',
    '作成日',
    '最終作業者',
    '作成者',
    '部署',
    'メモ',
    '',
  ];
  const body: TableElement[][] = [];
  // 一覧リスト生成
  articles
    .slice(0, perData)
    .forEach(element => (
      body.push([
        element.article_number,
        element.name,
        DateFormatter.date2str(new Date(element.created_at)),
        element.updated_display_name,
        element.created_display_name,
        element.organization_name,
        element.memo,
        <Button
          size="small"
          color="secondary"
          label="付替"
          onClick={() => handleClickExchange(element.article_id)}
        />,
      ])
    ));

  return (
    <div
      id="App"
      className="articles"
    >
      <SideBar apiManger={apiManger.type} currentPage="articles" />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList} />
          <section>
            <header>
              <h2>
                物件一覧
              </h2>
              <div className="count">
                <span>件数</span>
                {totalData}
                <span>件</span>
              </div>
            </header>

            <div className="table_box">
              <Table
                head={head}
                body={body}
                alignList={['c', 'l', 'c', 'l', 'l', 'c', 'c', 'c']}
                sortList={sortList}
                setSortBy={setSortBy}
                setHighlow={setHighlow}
                setIndex={setIndex}
                setActive={setActiveSort}
              />
            </div>
            <Pager
              totalPage={totalPage}
              currentPage={currentPage}
              callback={setCurrentPage}
              onChange={(page) => handlePaginate(page)}
            />
          </section>
        </div>
      </div>
    </div>
  );
};
