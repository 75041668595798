import con from '../../../connection';
import plans from './plans';
import files from './files';
var path = '/articles';
var articles = function (url) { return function (article_id) { return ({
    plans: plans("" + url + path + "/" + (article_id || '')),
    files: files("" + url + path + "/" + (article_id || '')),
    get: function (param) { return !article_id && param ? (
    // 物件一覧取得
    con.get(url + path, param)) : (
    // 物件詳細取得
    con.get("" + url + path + "/" + article_id, {})); },
    post: function (param) { return article_id ? (
    // 物件編集
    con.post("" + url + path + "/" + article_id, param || {})) : (
    // 物件新規作成
    con.post("" + url + path, param || {})); },
    // 物件削除(論理削除)
    delete: function () { return con.delete("" + url + path + "/" + article_id, {}); },
    // 物件復活
    restore: function () { return con.post("" + url + path + "/" + article_id + "/restore", {}); },
    /** 物件移動 */
    move: function (param) { return con.post("" + url + path + "/" + article_id + "/move", param); },
    /** 物件内アプリ連携起動数取得 */
    applications: function () { return con.get("" + url + path + "/" + article_id + "/applications", {}); },
}); }; };
export default articles;
