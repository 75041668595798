import destinations from './destinations/index';
import organizations from "./organizations";
var path = '/requests';
// /** 並び順 */
// sort_order: number,
// /** jsonファイル */
// File: File,
export var requests = function (url) { return function () {
    var fullPath = "" + url + path;
    return {
        destinations: destinations(fullPath),
        organizations: organizations(fullPath),
    };
}; };
export default requests;
