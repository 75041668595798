import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import { apiCompany, ApiManager } from '../../managers/api-manager';

export type ApiCompanyUsersSliceState = {};

const initialState: ApiCompanyUsersSliceState = {};

export const apiCompanyUsersSlice = createSlice({
  name: 'api-isp-company-users',
  initialState,
  reducers: {},
});

const asyncActions = {
  update:
    (data: {
      memberId: string;
      param: {
        organization_code: string;
        family_name: string;
        name: string;
        family_name_kana: string;
        name_kana: string;
        display_name: string;
        industry_id: number;
        occupation_id: number;
        role: number;
        is_information: boolean;
        is_log_admin: boolean;
        is_cadect1: boolean;
        is_cadect2: boolean;
        is_cadect3: boolean;
        is_cadect4: boolean;
        is_retirement: boolean;
        is_owner: boolean;
        authorities: string,
      };
      callback: {
        success: () => void,
        failed: () => void,
      }
    }): AppThunk =>
      async (dispatch) => {
        try {
          const connectionId = ApiManager.connectStart('');
          const res = await apiCompany
            .members(data.memberId)
            .post(data.param as any);
          data.callback.success();
          ApiManager.connectEnd(connectionId);
        } catch (e) {
          ApiManager.errorFunc(e);
          data.callback.failed();
        }
      },
};

export const apiCompanyUsersAction = Object.assign(
  apiCompanyUsersSlice.actions,
  asyncActions,
);
export const apiCompanyUsersReducer = apiCompanyUsersSlice.reducer;
