import con from '../../../../connection';
var path = '/send';
var send = function (url) { return function (request_id) {
    return {
        get: function (param, options) { return request_id
            ? con.get(url + path + '/' + request_id, {}, options === null || options === void 0 ? void 0 : options.noError, options === null || options === void 0 ? void 0 : options.noNetworkError)
            : con.get(url + path, param || {}, options === null || options === void 0 ? void 0 : options.noError, options === null || options === void 0 ? void 0 : options.noNetworkError); },
        delete: function (options) { return con.delete(url + path + '/' + request_id || '', {}, options === null || options === void 0 ? void 0 : options.noError); },
    };
}; };
export default send;
