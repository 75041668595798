import {json as jsonEx} from "@codemirror/lang-json";
import CodeMirror from '@uiw/react-codemirror';

type Props = {
  alertMessage: string,
  data: string,
  callback: (v: string) => void,
}

export const DirectJsonEdit = (props: Props) => {
  const { alertMessage, data, callback } = props;
  return (
    <div className={`code_editor_cnt${alertMessage ? ' alert' : ''}`}>
      <CodeMirror
        value={data}
        theme="light"
        extensions={[jsonEx()]}
        height="300px"
        // maxHeight="30vh"
        onChange={(e) => callback(e)}
        className={alertMessage ? 'alert' : ''}
      />
      <div className="alert_box">{alertMessage}</div>
   </div>

 ) 
}