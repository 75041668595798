import con from '../../../connection';
import activate from './activate/index';
import auth from './auth/index';
var path = '/users';
var users = function (url) { return function (user_id) { return ({
    auth: auth("" + url + path),
    activate: activate("" + url + path),
    get: function (param) {
        return !user_id && param
            ? con.get(url + path, param)
            : con.get("" + url + path + "/" + user_id, {});
    },
    post: function (param) {
        return con.post(url + path + (user_id ? "/" + user_id : ''), param);
    },
}); }; };
export default users;
