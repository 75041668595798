import con from '../../../../connection';
var path = '/reuse';
var reuse = function (url) { return function () {
    return {
        post: function () {
            return con.post(url + path, {});
        }
    };
}; };
export default reuse;
