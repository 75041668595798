import { BreadcrumbList } from "../../ui/breadcrumb-list/breadcrumb-list"
import { Header } from "../../ui/header/header"
import { SideBar } from "../../ui/sidebar/sidebar"
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { PageComponentDefaultProps } from "../../../models/page-component-default-props";
import { Button } from "../../ui/button/button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SortListType, Table } from "../../table/table";
import { authDebugInfo } from "./debug/auth.debug";
import { dialogAction } from '../../../slices/dialog-slice';
import { AddAuthorizationDialog } from "./dialog/add-authorization.dialog";
import { apiAdmin } from '../../../managers/api-manager';
import { DateFormatter } from "../../../utilities";
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { useAppDispatch } from "../../../app/hooks";
import { GetResponseMasterAuthoritiesDataEntity } from "../../../api/api/admin-web/master/authorities";

const breadCrumbList = [breadcrumbParts.isp.home, breadcrumbParts.isp.ispAuthorize]

const header = [
  '種別',
  'system名',
  '説明',
  'バージョン',
  '最終更新日',
  ''
];
const sortKey = {
  kind: 1,
  system: 2,
  description: 3,
  version: 4,
  update_at: 6,
  order: 5,
};

export const Authorization = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const dispatch = useAppDispatch();
  const [sortBy, setSortBy] = useState(sortKey.order);
  const [highlow, setHighlow] = useState(0);
  const [index, setIndex] = useState(6);
  const [activeSort, setActiveSort] = useState('active');
  const [info, setInfo] = useState<GetResponseMasterAuthoritiesDataEntity[]>([]);
  const maxSort = useMemo(() => {
    if (!info.length) return 1;
    const max = Math.max(...info.map((v) => v.sort_order));
    return max + 1;
  }, [info]);
  const tableBody = useMemo(() => {
    return info.map((v) => [
      v.kind === 1 ? '共通' : '機能別',
      v.system,
      v.description,
      v.version,
      DateFormatter.date2str(new Date(v.update_date), 'YYYYMMDD', '/'),
      <Button label="詳細" onClick={() => onClickDetail(v.id)} size="small" color="secondary" />
    ])
  }, [info]);
  const onClickDetail = (id: number) => {
    IspLogOperation('authManagerDetailClick');
    dispatch(dialogAction.push({
      title: '',
      element: <AddAuthorizationDialog id={id} callback={getList} />,
      className: 'add_authorization_dialog',
    }));
  }
  const onClickAdd = useCallback(() => {
    IspLogOperation('authManagerAddClick');
    dispatch(dialogAction.push({
      title: '',
      element: <AddAuthorizationDialog callback={() => getList()} initSort={maxSort} />,
      className: 'add_authorization_dialog',
    }))
  }, [maxSort]);
  const getList = useCallback(() => {
    apiAdmin.master().authorities().get({
      sort_by: sortBy,
      highlow: highlow,
    })
    .then((res: any) => {
      const data = res.body.data as GetResponseMasterAuthoritiesDataEntity[];
      if (data?.length) {
        setInfo(data);
      }
    })
  }, [sortBy, highlow]);

  /* テーブルソート */

  const sortList: SortListType[] = [
      {
        index: 0, // 何列目
        type: 'up', // 初期の昇降順
        key: sortKey.kind, // 何でソートするか
        active: '', // 現在のソートならactive
        callback: () => {
        },
      },
      {
        index: 1,
        type: 'up',
        key: sortKey.system,
        active: '',
        callback: () => {
        },
      },
      {
        index: 2,
        type: 'up',
        key: sortKey.description,
        active: '',
        callback: () => {
        },
      },
      {
        index: 3,
        type: 'up',
        key: sortKey.version,
        active: '',
        callback: () => {
        },
      },
      {
        index: 4,
        type: 'up',
        key: sortKey.update_at,
        active: '',
        callback: () => {
        },
      },
      {
        index: 6,
        type: 'up',
        key: sortKey.order,
        active: '',
        callback: () => {
        },
      },
    ];
    sortList.forEach(elm => {
      if (elm.index === index) {
        elm.type = highlow ? 'down' : 'up';
        elm.active = activeSort ? 'active' : '';
      } else {
        elm.type = 'up';
        elm.active = '';
      }
    });

  useEffect(() => {
    getList();
  }, [getList]);
  return (
    <div
      id="App"
    >
      <SideBar currentPage="authorize" />
      <div className="main_cnt">
        <Header />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadCrumbList} />
          <div className="inner">
            <section>
              <header>
                <h2>外部連携アプリ権限一覧</h2>
                <div className="count">
                    <span>件数</span>
                    {info?.length ?? '---'}
                    <span>件</span>
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Button
                      label="新規登録"
                      onClick={onClickAdd}
                    />
                  </div>
              </header>
              <div className="table_box">
                <Table
                  head={header}
                  body={tableBody}
                  alignList={['c', 'c', 'c', 'c', 'c', 'c']}
                  sortList={sortList}
                  setSortBy={setSortBy}
                  setHighlow={setHighlow}
                  setIndex={setIndex}
                  setActive={setActiveSort}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}