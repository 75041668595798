import React, { useCallback, useMemo } from 'react';
import { push } from '@lagunovsky/redux-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import logo from '../../../assets/images/common/B-Loop_logo_menu.svg';
import iconPortal from '../../../assets/images/common/icon_portal.svg';
import iconPortalOn from '../../../assets/images/common/icon_portal_on.svg';
import iconCorporate from '../../../assets/images/common/icon_corporate.svg';
import iconCorporateOn from '../../../assets/images/common/icon_corporate_on.svg';
import iconPerson from '../../../assets/images/common/icon_person.svg';
import iconPersonOn from '../../../assets/images/common/icon_person_on.svg';
import iconApp from '../../../assets/images/common/icon_app.svg';
import iconAppOn from '../../../assets/images/common/icon_app_on.svg';
import iconInfo from '../../../assets/images/common/icon_info.svg';
import iconInfoOn from '../../../assets/images/common/icon_info_on.svg';
import iconSystem from '../../../assets/images/common/icon_system.svg';
import iconSystemOn from '../../../assets/images/common/icon_system_on.svg';
import iconLog from '../../../assets/images/common/icon_log.svg';
import iconLogOn from '../../../assets/images/common/icon_log_on.svg';
import iconArticles from '../../../assets/images/common/icon_articles.svg';
import iconArticlesOn from '../../../assets/images/common/icon_articles_on.svg';
import { Mijissou } from '../../../index';
import { apiAdmin } from '../../../managers/api-manager';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

type ParentPages = 'portal' | 'company-list' | 'isp-user-list' | 'company-add' | 'isp-user-add' |
  'company-user-list' | 'company-user-add' | 'information' | 'information-list' | 'information-add' |
  'log-Management' | 'articles' | 'authorize' | 'request' | '';

type SidebarProps = {
  currentPage: ParentPages;
  apiManger?: 'admin' | 'company'
};

export const SideBar = (props: SidebarProps) => {
  const { currentPage, apiManger } = props;
  const dispatch = useAppDispatch();
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);

  const logAuth = useMemo(() => {
    if (apiManger === 'company') {
      return companyUserInfo.is_log_admin;
    }
    return true;
  }, [apiManger, companyUserInfo]);
  const infoAuth = useMemo(() => {
    if (apiManger === 'company') {
      return companyUserInfo.is_information;
    }
    return true;
  }, [apiManger, companyUserInfo]);
  /** 管理ポータル遷移 */
  const handleClickPortal = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      apiManger === 'company' ?
        dispatch(push(RoutingPath.companyStatus))
        :
        IspLogOperation('portalClick', () => {
          dispatch(push(RoutingPath.adminPortal));
        });
    },
    [dispatch],
  );
  /** 企業一覧遷移 */
  const handleClickCompanyList = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      IspLogOperation('companyListClick', () => {
        dispatch(push(RoutingPath.adminCompanyList));
      });
    },
    [],
  );
  /** 組織管理遷移 */
  const handleClickOrgManagement = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      CompanyLogOperation('organizationManagerClick', () => {
        dispatch(push(RoutingPath.companyOrgManagement));
      });
    },
    [],
  );
  /** 企業追加遷移 */
  const handlerClickCompanyAdd = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    IspLogOperation('companyAddClick', () => {
      dispatch(push(RoutingPath.adminCompanyAdd));
    });
    // Mijissou();
  }, []);
  /** ユーザー一覧遷移 */
  const handleClickUserList = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      apiManger === 'company' ?
        CompanyLogOperation('companyUserListClick', () => {
          dispatch(push(RoutingPath.companyUserList));
        }) :
        IspLogOperation('ispUserListClick', () => {
          dispatch(push(RoutingPath.adminUserList));
        });
    },
    [],
  );
  /** 物件一覧遷移 */
  const handleClickArticles = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      CompanyLogOperation('articleManagerClick', () => {
        dispatch(push(RoutingPath.companyArticles));
      })
    },
    [],
  );
  /** ユーザー追加遷移 */
  const handlerClickUserAdd = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    apiManger === 'company' ?
      CompanyLogOperation('companyUserAddClick', () => {
        dispatch(push(RoutingPath.companyInvitationUser));
      }) :
      IspLogOperation('ispUserAddClick', () => {
        dispatch(push(RoutingPath.adminInvitationUser));
      });
  }, []);
  /** インフォメーション一覧遷移 */
  const handleClickInfoList = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    apiManger === 'company' ?
      CompanyLogOperation('informationClick', () => {
        dispatch(push(RoutingPath.companyInfo));
      }) :
      IspLogOperation('informationClick', () => {
        dispatch(push(RoutingPath.adminInfo));
      });
  }, []);
  /** インフォメーション追加遷移 */
  const handlerClickAddInfo = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    apiManger === 'company' ?
      CompanyLogOperation('informationCreateClick', () => {
        dispatch(push(RoutingPath.companyInfoAdd));
      }) :
      IspLogOperation('informationCreateClick', () => {
        dispatch(push(RoutingPath.adminInfoAdd));
      });
  }, []);
  // /** ログ出力遷移 */
  const handlerClickExportLog = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    apiManger === 'company' ?
      CompanyLogOperation('logManagerClick', () => {
        dispatch(push(RoutingPath.companyLogExport));
      }) :
      IspLogOperation('logManagerClick', () => {
        dispatch(push(RoutingPath.adminLogExport));
      });
  }, []);
  /** 権限管理 */
  const handleClickAuthorize = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (apiManger === 'company') {
      return;
    }
    IspLogOperation('authManagerClick', () => {
      dispatch(push(RoutingPath.adminAuthorization));
    });
  }, []);
  const handleClickRequestSetting = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (apiManger === 'company') {
      return;
    }
    IspLogOperation('requestManagerClick', () => {
      dispatch(push(RoutingPath.adminRequestSetting));
    });
  }, []);

  // -------------------- selected用意 --------------------
  // ポータル
  const portalClass = currentPage === 'portal' ? 'selected' : '';
  // 企業一覧
  const companyListClass = currentPage === 'company-list' || currentPage === 'company-add' ? 'selected' : '';
  // MTHユーザー一覧
  const ispUserListClass = currentPage === 'isp-user-list' || currentPage === 'isp-user-add' ? 'selected' : '';
  // 企業ユーザー一覧
  const companyUserListClass = currentPage === 'company-user-list' || currentPage === 'company-user-add' ? 'selected' : '';
  // 物件一覧
  const articlesClass = currentPage === 'articles' ? 'selected' : '';
  // インフォメーション一覧
  const informationListClass = currentPage === 'information' ? 'selected' : '' || currentPage === 'information-list' || currentPage === 'information-add' ? 'selected' : '';
  // ログ管理
  const logClass = currentPage === 'log-Management' ? 'selected' : '';
  // システム管理
  const systemAuthClass = ['authorize', 'request'].includes(currentPage) ? 'selected' : '';
  return (
    <div className="side_bar">
      <div className="align_center logo_box">
        <a
          href="#"
          onClick={handleClickPortal}
        >
          <h1><img
            src={logo}
            alt=" ISP CLOUD"
          /></h1>
        </a>
        <h2>管理メニュー</h2>
      </div>
      <ul className="nav">
        <li className={portalClass}>
          <a
            href="#"
            onClick={handleClickPortal}
          >
            <img
              src={iconPortal}
              alt={apiManger === 'company' ? '企業管理ポータル' : 'MTH管理ポータル'}
              className="off"
            />
            <img
              src={iconPortalOn}
              alt={apiManger === 'company' ? '企業管理ポータル' : 'MTH管理ポータル'}
              className="on"
            />
            {apiManger === 'company' ? '企業管理ポータル' : 'MTH管理ポータル'}
          </a>
        </li>
        {apiManger === 'company' ?
          <li className={companyListClass}>
            <a
              href="#"
              onClick={handleClickOrgManagement}
            >
              <img
                src={iconCorporate}
                alt="組織管理"
                className="off"
              />
              <img
                src={iconCorporateOn}
                alt="組織管理"
                className="on"
              />
              組織管理
            </a>
          </li>
          :
          <li className={companyListClass}>
            <a
              href="#"
              onClick={handleClickCompanyList}
            >
              <img
                src={iconCorporate}
                alt="企業管理"
                className="off"
              />
              <img
                src={iconCorporateOn}
                alt="企業管理"
                className="on"
              />
              企業管理
            </a>
            <ul>
              <li className={currentPage === 'company-list' ? 'selected' : ''}><a
                href="#"
                onClick={handleClickCompanyList}
              >-&nbsp;企業一覧</a></li>
              <li className={currentPage === 'company-add' ? 'selected' : ''}><a
                href="#"
                onClick={handlerClickCompanyAdd}
              >-&nbsp;企業登録</a></li>
            </ul>
          </li>
        }
        {apiManger === 'company' ?
          <>
          <li className={companyUserListClass}>
            <a
              href="#"
              onClick={handleClickUserList}
            >
              <img
                src={iconPerson}
                alt="ユーザー管理"
                className="off"
              />
              <img
                src={iconPersonOn}
                alt="ユーザー管理"
                className="on"
              />
              ユーザー管理
            </a>
            <ul>
              <li className={currentPage === 'company-user-list' ? 'selected' : ''}><a
                href="#"
                onClick={handleClickUserList}
              >-&nbsp;ユーザー一覧</a></li>
              <li className={currentPage === 'company-user-add' ? 'selected' : ''}><a
                href="#"
                onClick={handlerClickUserAdd}
              >-&nbsp;ユーザー登録</a></li>
            </ul>
          </li>
          <li className={articlesClass}>
            <a
              href="#"
              onClick={handleClickArticles}
            >
              <img
                src={iconArticles}
                alt="物件管理"
                className="off"
              />
              <img
                src={iconArticlesOn}
                alt="物件管理"
                className="on"
              />
              物件管理
            </a>
            <ul>
              <li className={currentPage === 'articles' ? 'selected' : ''}><a
                href="#"
                onClick={handleClickArticles}
              >-&nbsp;物件一覧</a></li>
            </ul>
          </li>
          </>
          :
          <li className={ispUserListClass}>
            <a
              href="#"
              onClick={handleClickUserList}
            >
              <img
                src={iconPerson}
                alt="MTHユーザー管理"
                className="off"
              />
              <img
                src={iconPersonOn}
                alt="MTHユーザー管理"
                className="on"
              />
              MTHユーザー管理
            </a>
            <ul>
              <li className={currentPage === 'isp-user-list' ? 'selected' : ''}><a
                href="#"
                onClick={handleClickUserList}
              >-&nbsp;MTHユーザー一覧</a></li>
              <li className={currentPage === 'isp-user-add' ? 'selected' : ''}><a
                href="#"
                onClick={handlerClickUserAdd}
              >-&nbsp;MTHユーザー登録</a></li>
            </ul>
          </li>
        }
        <li className="disabled">
          <a href="#">
            <img
              src={iconApp}
              alt="アプリ管理"
              className="off"
            />
            <img
              src={iconAppOn}
              alt="アプリ管理"
              className="on"
            />
            アプリ管理
          </a>
        </li>
        <li className={(infoAuth || apiManger !== 'company') ? informationListClass : 'disabled'}>
          <a
            href="#"
            onClick={handleClickInfoList}
          >
            <img
              src={iconInfo}
              alt="インフォメーション管理"
              className="off"
            />
            <img
              src={iconInfoOn}
              alt="インフォメーション管理"
              className="on"
            />
            インフォメーション管理
          </a>
          <ul>
            <li className={currentPage === 'information-list' ? 'selected' : ''}>
              <a href="#" onClick={handleClickInfoList} >-&nbsp;インフォ一覧</a>
            </li>
            <li className={currentPage === 'information-add' ? 'selected' : ''}>
              <a href="#" onClick={handlerClickAddInfo} >-&nbsp;インフォ追加</a>
            </li>
          </ul>
        </li>
        {apiManger === 'company' ?
          <></>
          :
          <li className={systemAuthClass}>
            {/* <a href="#" onClick={handleClickAuthorize}> */}
            <a href="#" onClick={handleClickRequestSetting}>
              <img
                src={iconSystem}
                alt="システム設定"
                className="off"
              />
              <img
                src={iconSystemOn}
                alt="システム設定"
                className="on"
              />
              システム設定
            </a>
            <ul>
              {/* <li className={currentPage === 'authorize' ? 'selected' : ''}> */}
              <li className="disabled">
                <a href="#" onClick={handleClickAuthorize} >-&nbsp;権限マスタ管理</a>
              </li>
              <li className={currentPage === 'request' ? 'selected' : ''}>
                <a href="#" onClick={handleClickRequestSetting} >-&nbsp;依頼設定</a>
              </li>
            </ul>
          </li>
        }
        <li className={`${logAuth ? '' : 'disabled '}${logClass}`}>
          <a
            href="#"
            onClick={handlerClickExportLog}
          >
            <img
              src={iconLog}
              alt="ログ管理"
              className="off"
            />
            <img
              src={iconLogOn}
              alt="ログ管理"
              className="on"
            />
            ログ管理
          </a>
          {/* <li className={currentPage === 'isp-user-add' ? 'selected' : ''}><a
                href="#"
                onClick={handlerClickExportLog}
              >-&nbsp;ログ出力</a></li> */}
        </li>
      </ul>
    </div>
  );
};
