import { useCallback } from 'react';
import { useAppDispatch } from '../app/hooks';
import { dialogAction } from '../slices/dialog-slice';
const MESSAGE = [
  'この機能はα版ではご利用になれません。'
]
export const useMijissou = () => {
  const dispatch = useAppDispatch();
  const pushMijissou = useCallback((message: string[] = MESSAGE) => {
    dispatch(dialogAction.pushMessage({
      title: '確認',
      message: MESSAGE,
      buttons: [
        { label: 'OK', callback: () => dispatch(dialogAction.pop()) },
      ],
    }));
  }, [dispatch]);
  return {
    pushMijissou,
  };
}