import {createSlice} from '@reduxjs/toolkit';
import {apiAdmin} from '../../managers/api-manager';

export type IspIsloginSliceState = {};

const initialState: IspIsloginSliceState = {};

const islogin = apiAdmin.islogin();

export const apiIspIsloginSlice = createSlice({
  name: 'api-isp-islogin',
  initialState,
  reducers: {},
});

export const apiIspIsloginAction = apiIspIsloginSlice.actions;
export const apiIspIsloginReducer = apiIspIsloginSlice.reducer;
