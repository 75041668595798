import con from '../../../../../connection';
var path = '/files';
var files = function (url) { return function (file_id) { return ({
    // 物件ファイルダウンロード
    get: function (param) { return con.getFile("" + url + path, param); },
    // 物件ファイルアップロード
    post: function (param) { return con.post("" + url + path, param); },
    // 物件ファイル削除
    delete: function () { return con.delete("" + url + path + "/" + (file_id || ''), {}); },
}); }; };
export default files;
