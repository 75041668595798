import con from '../../../../connection';
var path = '/set';
var set = function (url) { return function () {
    return {
        post: function (param) {
            return con.post(url + path, param);
        },
    };
}; };
export default set;
