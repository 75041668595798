import { Validation } from '../../../managers/validation/validation';
import React, { LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import './input.scss';
import iconCloseOn from '../../../assets/images/common/cross_on.svg';
import iconCloseOff from '../../../assets/images/common/cross_off.svg';
import iconCalendarOn from '../../../assets/images/common/icon_calendar_on.svg';
import iconCalendarOff from '../../../assets/images/common/icon_calendar_off.svg';
import { Tooltip } from '../tooltip/tooltip';
import iconSearch from '../../../assets/images/common/icon_search_input.svg';

export type InputProps = {
  type?: string,
  className?: string,
  boxSize?: 'x_small' | 'small' | 'medium' | 'large' | 'auto',//英数字3文字の場合はx_small、maxLengthにサイズを合わせるときはautoを使う
  placeholder?: string,
  maxLength?: number,
  disabled?: boolean,
  validations?: Validation[],
  /** バリデーション切り替え用 バリデーションエラーメッセージを消した後にdisabledをかけたい時 */
  asyncDisabled?: boolean,
  overErrorIgnore?: boolean,
  onClickClearDate?: () => void,
  edgeEle?: globalThis.Window | HTMLElement,
  isSearch?: boolean,
  onEnterKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const testAll = (v: string, validations: Validation[]) => validations.reduce((pre, validation, i) => {
  const bool = validation.test(v);
  return pre && bool;
}, true);
const getErrorMessagesFromValidations = (validations: Validation[]) => validations.reduce((pre: string[], validation, i) => (!validation.valid ? [...pre, ...validation.errorMessages] : pre), []);

export const Input = React.forwardRef((props: InputProps, ref: LegacyRef<HTMLInputElement>) => {
  const {
    type,
    className,
    boxSize,
    placeholder,
    maxLength,
    disabled,
    validations,
    asyncDisabled,
    overErrorIgnore,
    onClickClearDate: _onClickClearDate,
    edgeEle,
    isSearch,
    ...defaultProps
  } = props;
  // - ref -
  const wrapRef = useRef<HTMLDivElement>(null);
  const [isInit, setIsInit] = useState(false);
  const [focused, setFocused] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const [valid, setValid] = useState(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [errorContainerHeight, setErrorContainerHeight] = useState(0);
  const [errorContainerWidth, setErrorContainerWidth] = useState(0);
  const errorContainerEle = useRef<HTMLDivElement>(null);
  // - effect -
  // -- errorContainerEle の高さと幅を取得 --
  useEffect(
    () => {
      if (errorContainerEle && errorContainerEle.current) {
        const height = errorContainerEle.current.offsetHeight;
        const width = errorContainerEle.current.offsetWidth;
        setErrorContainerHeight(height);
        setErrorContainerWidth(width);
      }
    },
    [errorContainerEle, errorMessages],
  );
  // useEffect(() => {
  //   if (!isInit) {
  //     setIsInit(true);
  //     if (!defaultProps.value) {
  //       return;
  //     }
  //   }
  //   if (!validations) return;
  //   const testAllResult = testAll(String(defaultProps.value ?? ''), validations);
  //   setValid(testAllResult);
  //   setErrorMessages(getErrorMessagesFromValidations(validations));
  // }, [defaultProps.value])
  const onClickDelete = useCallback(() => {
    _onClickClearDate && _onClickClearDate();
  }, [_onClickClearDate]);

  return (
    type === 'checkbox' || type === 'radio' || type === 'checkBox' ?
      <input
        {...defaultProps}
        type={type}
        className={`${boxSize ? ` ${boxSize}` : ''}${className ? ` ${className}` : ''}`}
        placeholder={placeholder}
        maxLength={maxLength}
        size={maxLength}
        disabled={disabled && (!errorMessages.length && asyncDisabled)}
        ref={ref}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (validations) {
            const testAllResult = testAll(String(e.target.value), validations);
            setValid(testAllResult);
            setErrorMessages(getErrorMessagesFromValidations(validations));
          }
          if (props.onChange) {
            props.onChange(e);
          }
        }}
      />
      :
      <div
        className={`input_wrap${valid ? '' : ' alert'}${type === 'date' ? ' input_date' : ''}${disabled ? ' disabled' : ''} ${isSearch ? 'search' : ''}`}
        ref={wrapRef}
      >
        <input
          {...defaultProps}
          type={type}
          min={focused ? defaultProps.min : undefined}
          max={focused ? defaultProps.max : undefined}
          className={`${boxSize ? ` ${boxSize}` : ''}${className ? ` ${className}` : ''}${type === 'date' ? ' input_date__form' : ''}`}
          placeholder={placeholder}
          maxLength={maxLength}
          size={maxLength}
          disabled={disabled && (!errorMessages.length && asyncDisabled)}
          ref={ref}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (type === 'number') {
              e.target.value = String(Number(e.target.value));
            }
            if (validations) {
              const testAllResult = testAll(String(e.target.value), validations);
              setValid(testAllResult);
              setErrorMessages(getErrorMessagesFromValidations(validations));
            }
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          // onClick={(e: any) => {
          //   if (validations) {
          //     const testAllResult = testAll(String(e.target.value), validations);
          //     setValid(testAllResult);
          //     setErrorMessages(getErrorMessagesFromValidations(validations));
          //   }
          //   if (props.onClick) {
          //     props.onClick(e);
          //   }
          // }}
          onFocus={(e) => {
            if (validations) {
              const testAllResult = testAll(String(e.target.value), validations);
              setValid(testAllResult);
              setErrorMessages(getErrorMessagesFromValidations(validations));
            }
            setFocused(true);
          }}
          onBlur={(e) => {
            if (validations) {
              const testAllResult = testAll(String(e.target.value), validations);
              setValid(testAllResult);
              setErrorMessages(getErrorMessagesFromValidations(validations));
            }
            if (props.onBlur) {
              props.onBlur(e);
            }
            setFocused(false);
          }}
          onMouseOver={(e) => {
            if (type === 'date' && validations && !overErrorIgnore) {
              const testAllResult = testAll(String(e.currentTarget.value), validations);
              setValid(testAllResult);
              setErrorMessages(getErrorMessagesFromValidations(validations));
            }
            setMouseOver(true);
          }}
          onMouseLeave={(e) => {
            if (type === 'date' && validations && !overErrorIgnore) {
              const testAllResult = testAll(String(e.currentTarget.value), validations);
              setValid(testAllResult);
              setErrorMessages(getErrorMessagesFromValidations(validations));
            }
            setMouseOver(false);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              props.onEnterKeyPress?.(e);
            }
            props.onKeyPress?.(e);
          }}
        />
        {((!type || type === 'text') && isSearch) && (
          <div className="input_search">
            <img
              src={iconSearch}
              alt=""
            />
          </div>
        )}
        {type === 'date' && <div className="input_date__actions">
          <button onClick={onClickDelete}>
            <img
              src={disabled ? iconCloseOff : iconCloseOn}
              alt=""
            />
          </button>
          <img
            src={disabled ? iconCalendarOff : iconCalendarOn}
            alt=""
          />
        </div>}
        {(!valid && (focused || mouseOver)) && (
          <Tooltip
            relativeEle={wrapRef.current}
            element={<>
              {errorMessages.map((message, i) => (
                <div key={`invalid_message_${i}`}>{message}</div>
              ))}
            </>}
            positionType="absolute"
            blowing
            error
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            autoPos={{ h: 'left', v: 'top' }}
            edgeSupport={edgeEle && { ele: edgeEle }}
          />
        )}
      </div>
  );
});

// ---------------------------------------- defaultProps ----------------------------------------
Input.defaultProps = {
  type: 'text',
  className: '',
  boxSize: undefined,
  placeholder: '',
  maxLength: undefined,
  size: undefined,
  disabled: undefined,
};
