import con from '../../../../connection';
import upload from './upload/index';
var path = '/destinations';
var destinations = function (url, info_id) { return function () { return ({
    upload: upload(url + path),
    get: function (param) {
        return !info_id
            ? con.get(url + path, param || {})
            : con.getFile(url + "/" + info_id + path, {});
    },
}); }; };
export default destinations;
