import { cloneDeep } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

type SelectData = {
  id: any,
  index: number,
}

type Props<T = {}> = {
  list: T[],
  idKey: keyof T,
  ignoreList?: T[],
}

export const useTableSelect = <T = {}>(props: Props<T>) => {
  const { list: _list, idKey, ignoreList } = props;

  const list = useMemo(() => {
    if (!ignoreList) return _list;
    return _list.filter((data) => !ignoreList.map((v) => v[idKey]).includes(data[idKey]));
  }, [_list, ignoreList, idKey]);
  const [selected, setSelected] = useState<SelectData[]>([]);
  const prevSelect = useRef<null | SelectData>(null);

  const select = useCallback((index: number, e: React.MouseEvent<HTMLTableRowElement | HTMLButtonElement, MouseEvent>) => {
    const data = list[index];
    if (!data) throw new Error('選択した項目が存在しません！');
    const find = selected.find((v) => v.id === data[idKey]);
    const findIndex = selected.findIndex((v) => v.id === data[idKey]);

    if (e.shiftKey && prevSelect.current && (prevSelect.current.index !== index)) {
      const arr: SelectData[] = [];
      const isPlus = prevSelect.current.index < index;
      const forData = { i: isPlus ? prevSelect.current.index + 1 : index, operation: isPlus ? index : prevSelect.current.index };
      for (let i = forData.i; i <= forData.operation; i++) {
        const id = list[i]?.[idKey];
        if (!data) continue;
        const find = selected.find((v) => v.id === list[i]?.[idKey]);

        if (!find) {
          arr.push({ id, index: i });
        }
      }
      setSelected([...selected, ...arr]);
    } else if (e.ctrlKey || e.metaKey) {
      prevSelect.current = { id: data[idKey], index }
      if (find) {
        selected.splice(findIndex, 1);
      } else {
        selected.push({ id: data[idKey], index });
      }
      setSelected(cloneDeep(selected));
      
    } else {
      prevSelect.current = { id: data[idKey], index }
      if (selected.length > 1) {
        setSelected([{ id: data[idKey], index }]);
      } else {
        setSelected(find ? [] : [{ id: data[idKey], index }])
      }
    }

  }, [list, selected, prevSelect]);

  const resetSelect = useCallback(() => {
    setSelected([]);
  }, []);
  return {
    selected,
    onSelect: select,
    resetSelect,
  }
}