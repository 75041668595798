import { useCallback, useEffect, useMemo, useState } from "react"
import { GetResponseMasterDestinationsDataEntity } from "../../../../api/api/admin-web/master/requests/destinations";
import { apiAdmin, ApiManager } from '../../../../managers/api-manager';

export const useGetRequestsData = () => {
  const [info, setInfo] = useState<GetResponseMasterDestinationsDataEntity | null>(null);
  const persons = useMemo(() => info?.persons ?? [], [info]);
  const organizations = useMemo(() => info?.organizations ?? [], [info]);
  const getList = useCallback(() => {
    apiAdmin.master().requests().destinations().get().then((res) => {
      setInfo(res.body.data);
    }).catch((e) => ApiManager.errorFunc(e))
  }, []);

  useEffect(() => {
    getList();
  }, []);
  return { 
    getList,
    persons,
    organizations,
  }
}