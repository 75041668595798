import { useAppDispatch, useAppSelector } from '../../app/hooks';
import './dialog.scss';
import { dialogAction } from '../../slices/dialog-slice';
import { useDidMount } from '../../hooks/life-cycle';

export const Dialog = () => {
  const dispatch = useAppDispatch();

  const handleClickBrowserBack = () => {
    dispatch(dialogAction.popAll());
  };

  useDidMount(
    () => {
      window.addEventListener('popstate', handleClickBrowserBack);
    });

  const { dialogs } = useAppSelector((state) => state.dialog);
  return dialogs.length ? (
    <>
      {dialogs.map((v, index) => (
        <div onClick={() => {
          if (v.outClickPop) {
            dispatch(dialogAction.pop());
          }
        }} key={`dialog-element_${index}`} id={v?.id || ''} className={`dialog_wrap${v.className ? ` ${v.className}` : ''}`}>
          <div className="dialog" onClick={(e) => e.stopPropagation()}>
            {v.title ? (
              <header>{v.title}</header>
            ) : (<></>)}
            < div className="dialog_body">
              <div className="inner">{v.element}</div>
            </div>
          </div>
        </div>
      ))
      }
    </>
  ) : (<></>);
};
