import {RoutingPath} from '../routes/routing-path';

export const breadcrumbParts = {
  isp: {
    home: {
      label: 'HOME',
      route: RoutingPath.adminPortal,
    },
    companyList: {
      label: '企業一覧',
      route: RoutingPath.adminCompanyList,
    },
    companyDetail: {
      label: ' ',
      route: RoutingPath.adminCompanyDetail,
    },
    companyRegister: {
      label: '新規企業登録',
      route: RoutingPath.adminCompanyAdd,
    },
    companyEdit: {
      label: '企業登録変更',
      route: RoutingPath.adminCompanyEdit,
    },
    companyStatus: {
      label: '状況一覧',
      route: RoutingPath.adminCompanyStatus,
    },
    organizationManage: {
      label: '組織管理',
      route: RoutingPath.adminOrgManagement,
    },
    companyUserList: {
      label: '企業ユーザー管理',
      route: RoutingPath.adminCompanyUserList
    },
    companyUserAdd: {
      label: 'ユーザー新規登録',
      route: RoutingPath.adminCompanyInvitationUser,
    },
    companyUserEdit: {
      label: 'ユーザー情報編集',
      route: RoutingPath.adminCompanyUserEdit,
    },
    ispUserList: {
      label: 'MTHユーザー一覧',
      route: RoutingPath.adminUserList,
    },
    ispUserRegister: {
      label: 'MTHユーザー登録',
      route: RoutingPath.adminInvitationUser,
    },
    ispUserUpdate: {
      label: 'MTHユーザー更新',
      route: RoutingPath.adminUserEdit,
    },
    ispInfo: {
      label: 'インフォメーション一覧',
      route: RoutingPath.adminInfo,
    },
    ispInfoDetail: {
      label: 'インフォメーション詳細',
      route: RoutingPath.adminInfoDetail,
    },
    ispInfoAdd: {
      label: 'インフォメーション新規',
      route: RoutingPath.adminInfoAdd,
    },
    ispInfoSelect: {
      label: '通知先設定',
      route: RoutingPath.adminInfoSelect,
    },
    ispLog: {
      label: '企業操作ログ',
      route:RoutingPath.adminLogExport,
    },
    ispAuthorize: {
      label: '権限管理',
      route: RoutingPath.adminAuthorization,
    },
    ispRequestSetting: {
      label: '依頼設定',
      route: RoutingPath.adminRequestSetting,
    }
  },
  company: {
    home: {
      label: 'HOME',
      route: RoutingPath.companyStatus,
    },
    organizationManage: {
      label: '組織管理',
      route: RoutingPath.companyOrgManagement,
    },
    userList: {
      label: 'ユーザー一覧',
      route: RoutingPath.companyUserList
    },
    userAdd: {
      label: 'ユーザー新規登録',
      route: RoutingPath.companyInvitationUser,
    },
    userEdit: {
      label: 'ユーザー情報編集',
      route: RoutingPath.companyUserEdit,
    },
    userSetting: {
      label: 'ユーザー設定',
      route: RoutingPath.companyUserSetting,
    },
    editPassword: {
      label: 'パスワード変更',
      route: RoutingPath.companyEditPassword,
    },
    info: {
      label: 'インフォメーション一覧',
      route: RoutingPath.companyInfo,
    },
    infoDetail: {
      label: 'インフォメーション詳細',
      route: RoutingPath.companyInfoDetail,
    },
    infoAdd: {
      label: 'インフォメーション新規',
      route: RoutingPath.companyInfoAdd,
    },
    infoSelect: {
      label: '通知先設定',
      route: RoutingPath.adminInfoSelect,
    },
    articles: {
      label: '物件一覧',
      route: RoutingPath.companyArticles,
    },
    ispLog: {
      label: '操作ログ',
      route:RoutingPath.companyLogExport,
    },
  },
};
