import con from '../../../../connection';
import address from './address/index';
import { merger } from './merger';
var path = '/organizations';
export var organizations = function (url) { return function (organization_code) {
    return {
        address: address("" + url + path + "/" + organization_code),
        merger: merger("" + url + path + "/" + organization_code),
        get: function () {
            return organization_code
                ? con.get("" + url + path + "/" + organization_code, {})
                : con.get(url + path, {});
        },
        post: function (param) {
            return con.post(url + path + (organization_code ? "/" + organization_code : ''), param);
        },
        delete: function () { return con.delete("" + url + path + "/" + organization_code, {}); }
    };
}; };
