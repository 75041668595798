import authority from "./authorities/index";
import requests from "./requests";
var path = '/master';
var master = function (url) { return function () {
    return {
        authorities: authority("" + url + path),
        requests: requests("" + url + path),
    };
}; };
export default master;
