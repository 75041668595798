import con from '../../../../connection';
import reinvite from './reinvite';
import authorities from './authorities/index';
var path = '/members';
export var members = function (url) { return function (member_id) {
    return {
        reinvite: reinvite("" + url + path + "/" + member_id),
        get: function (param) {
            return !member_id && param
                ? con.get(url + path, param)
                : con.get("" + url + path + "/" + member_id, {});
        },
        authorities: authorities("" + url + path),
        post: function (param) {
            return con.post(url + path + (member_id ? "/" + member_id : ''), param);
        },
        delete: function () {
            if (!member_id)
                throw new Error('member_id is not found !!');
            return con.delete(url + path + '/' + member_id, {});
        },
    };
}; };
