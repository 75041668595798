import { CompanyIdType, logType } from './log-type';
import { apiCompany } from '../../managers/api-manager';
import { apiLog } from './log-data';

const addCompanyLog = (logData: logType, callback?: () => void) => {
  apiCompany.logs().operations().post({ ...logData }).finally(callback);
};

export const CompanyLogOperation = (id: CompanyIdType, callback?: () => void) => {
  switch (id) {
    case 'settingLogOutClick': // 設定－ログアウト選択
      addCompanyLog({
        scene_id: apiLog.sceneId.settingMenu,
        sub_scene_id: apiLog.subSceneId.logout,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'settingProfileClick': // 設定－表示設定選択
      addCompanyLog({
        scene_id: apiLog.sceneId.settingMenu,
        sub_scene_id: apiLog.subSceneId.displaySetting,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'settingPasswordClick': // 設定－パスワード変更選択
      addCompanyLog({
        scene_id: apiLog.sceneId.settingMenu,
        sub_scene_id: apiLog.subSceneId.changePassword,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerClick': // 組織管理
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerAddClick': // 組織管理－新規登録押下
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        sub_scene_id: apiLog.subSceneId.new,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerAddCheckClick': // 組織管理－新規登録－確認押下
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        sub_scene_id: apiLog.subSceneId.new,
        sub_scene2_id: apiLog.subSceneId.input,
        sub_scene3_id: apiLog.subSceneId.confirm,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerAddCheckCancel': // 組織管理－新規登録－戻る押下
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        sub_scene_id: apiLog.subSceneId.new,
        sub_scene2_id: apiLog.subSceneId.input,
        sub_scene3_id: apiLog.subSceneId.return,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerAddCancel': // 組織管理－新規登録－キャンセル押下
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        sub_scene_id: apiLog.subSceneId.new,
        sub_scene2_id: apiLog.subSceneId.confirm,
        sub_scene3_id: apiLog.subSceneId.cancel,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerEditClick': // 組織管理－変更押下
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        sub_scene_id: apiLog.subSceneId.edit,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerEditCheckClick': // 組織管理－編集－確認押下
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        sub_scene_id: apiLog.subSceneId.edit,
        sub_scene2_id: apiLog.subSceneId.input,
        sub_scene3_id: apiLog.subSceneId.confirm,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerEditCheckCancel': // 組織管理－編集－戻る押下
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        sub_scene_id: apiLog.subSceneId.edit,
        sub_scene2_id: apiLog.subSceneId.input,
        sub_scene3_id: apiLog.subSceneId.return,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerEditCancel': // 組織管理－編集－キャンセル押下
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        sub_scene_id: apiLog.subSceneId.edit,
        sub_scene2_id: apiLog.subSceneId.confirm,
        sub_scene3_id: apiLog.subSceneId.cancel,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerDelete': // 組織管理－削除押下
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        sub_scene_id: apiLog.subSceneId.delete,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'organizationManagerDeleteCancel': // 組織管理－削除－キャンセル
      addCompanyLog({
        scene_id: apiLog.sceneId.organizationManagement,
        sub_scene_id: apiLog.subSceneId.delete,
        sub_scene2_id: apiLog.subSceneId.cancel,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'companyUserListClick': // ユーザー管理
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'companyUserAddClick': // ユーザー管理－ユーザ－新規登録
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        sub_scene_id: apiLog.subSceneId.new,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'companyUserAddCancel': // ユーザー管理－ユーザ－新規登録－キャンセル
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        sub_scene_id: apiLog.subSceneId.new,
        sub_scene2_id: apiLog.subSceneId.cancel,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'companyUserAddOrganizationSelect': // ユーザー管理－ユーザ－新規登録－組織指定
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        sub_scene_id: apiLog.subSceneId.new,
        sub_scene2_id: apiLog.subSceneId.organization,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'companyUserAddOrganizationList': // ユーザー管理－ユーザ－新規登録－組織一覧
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        sub_scene_id: apiLog.subSceneId.new,
        sub_scene2_id: apiLog.subSceneId.organization,
        action_id: apiLog.actionId.search,
      }, callback);
      break;
    case 'companyUserAddOrganizationAddClick': // ユーザー管理－ユーザ－新規登録－組織一覧－選択
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        sub_scene_id: apiLog.subSceneId.new,
        sub_scene2_id: apiLog.subSceneId.organization,
        action_id: apiLog.actionId.select,
      }, callback);
      break;
    case 'companyUserEditClick': // ユーザー管理ーユーザー－変更
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        sub_scene_id: apiLog.subSceneId.edit,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'companyUserEditCancel': // ユーザー管理－ユーザ－編集－キャンセル
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        sub_scene_id: apiLog.subSceneId.edit,
        sub_scene2_id: apiLog.subSceneId.cancel,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'companyUserEditOrganizationSelect': // ユーザー管理－ユーザ－編集－組織指定
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        sub_scene_id: apiLog.subSceneId.edit,
        sub_scene2_id: apiLog.subSceneId.organization,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case 'companyUserEditOrganizationList': // ユーザー管理－ユーザ－編集－組織一覧
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        sub_scene_id: apiLog.subSceneId.edit,
        sub_scene2_id: apiLog.subSceneId.organization,
        action_id: apiLog.actionId.search,
      }, callback);
      break;
    case 'companyUserEditOrganizationAddClick': // ユーザー管理－ユーザ－編集－組織一覧－選択
      addCompanyLog({
        scene_id: apiLog.sceneId.companyUserManagement,
        sub_scene_id: apiLog.subSceneId.edit,
        sub_scene2_id: apiLog.subSceneId.organization,
        action_id: apiLog.actionId.select,
      }, callback);
      break;
    case "informationClick": // インフォメーション管理－押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationCreateClick": // インフォメーション管理－新規作成－押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.new,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationCreateCheckClick": // インフォメーション管理－新規作成－確認押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.new,
        sub_scene3_id: apiLog.subSceneId.confirm,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationCreateAddClick": // インフォメーション管理－新規作成－登録押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.new,
        sub_scene3_id: apiLog.subSceneId.entry,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationCreateCancelClick": // インフォメーション管理－新規作成－キャンセル押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.new,
        sub_scene3_id: apiLog.subSceneId.cancel,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationCreateNotificationClick": // インフォメーション管理－新規作成－通知先設定押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.new,
        sub_scene3_id: apiLog.subSceneId.notificationTargetSetting,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationNotificationUserSelect": // インフォメーション管理－通知先設定－通知先ユーザー選択
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
        sub_scene3_id: apiLog.subSceneId.notificationUser,
        action_id: apiLog.actionId.select,
      }, callback);
      break;
    case "informationNotificationTemplateClick": // インフォメーション管理ー通知先設定－テンプレートから指定－押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
        sub_scene3_id: apiLog.subSceneId.templateSelect,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationNotificationTemplateSelect": // インフォメーション管理ー通知先設定－テンプレートから指定－選択
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
        sub_scene3_id: apiLog.subSceneId.templateSelect,
        action_id: apiLog.actionId.select,
      }, callback);
      break;
    case "informationNotificationEditClick": // インフォメーション管理ー通知先設定－テンプレートから指定－編集押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
        sub_scene3_id: apiLog.subSceneId.templateSelect,
        sub_scene4_id: apiLog.subSceneId.edit,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationNotificationEditUpdateClick": // インフォメーション管理ー通知先設定－テンプレートから指定－編集－更新押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
        sub_scene3_id: apiLog.subSceneId.templateSelect,
        sub_scene4_id: apiLog.subSceneId.update,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationNotificationEditDeleteClick": // インフォメーション管理ー通知先設定－テンプレートから指定－編集－削除押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
        sub_scene3_id: apiLog.subSceneId.templateSelect,
        sub_scene4_id: apiLog.subSceneId.delete,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationNotificationNewSaveClick": // インフォメーション管理ー通知先設定－新規保存押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
        sub_scene3_id: apiLog.subSceneId.template,
        sub_scene4_id: apiLog.subSceneId.new,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationNotificationOverwritingSaveClick": // インフォメーション管理ー通知先設定－上書保存－押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
        sub_scene3_id: apiLog.subSceneId.template,
        sub_scene4_id: apiLog.subSceneId.overwritingSave,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationNotificationCsvImportClick": // インフォメーション管理ー通知先設定－csv取込－押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.notificationTargetSetting,
        sub_scene3_id: apiLog.subSceneId.csvImport,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationListDetailClick": // インフォメーション管理－一覧取得－詳細押下（ダブルクリック）
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.detail,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationListDetailDeleteClick": // インフォメーション管理－一覧取得－詳細－削除押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.detail,
        sub_scene3_id: apiLog.subSceneId.delete,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationListDetailDeleteCancel": // インフォメーション管理－一覧取得－詳細－削除－キャンセル
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.detail,
        sub_scene3_id: apiLog.subSceneId.delete,
        sub_scene4_id: apiLog.subSceneId.cancel,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationListDetailCopyClick": // インフォメーション管理－一覧取得－詳細－複製押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.detail,
        sub_scene3_id: apiLog.subSceneId.copy,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationListDetailEditClick": // インフォメーション管理－一覧取得－詳細－編集押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.detail,
        sub_scene3_id: apiLog.subSceneId.edit,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationListDetailEditCancel": // インフォメーション管理－一覧取得－詳細－編集－キャンセル
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.detail,
        sub_scene3_id: apiLog.subSceneId.edit,
        sub_scene4_id: apiLog.subSceneId.cancel,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "informationListDetailDeliveryClick": // インフォメーション管理－一覧取得－詳細－配信先再指定押下
      addCompanyLog({
        scene_id: apiLog.sceneId.informationManagement,
        sub_scene2_id: apiLog.subSceneId.detail,
        sub_scene3_id: apiLog.subSceneId.reSelect,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "logManagerClick": // ログ管理－押下
      addCompanyLog({
        scene_id: apiLog.sceneId.logManagement,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "logManagerDlClick": // ログ管理－DL－押下
      addCompanyLog({
        scene_id: apiLog.sceneId.logManagement,
        sub_scene2_id: apiLog.subSceneId.dl,
        action_id: apiLog.actionId.click,
      }, callback);
      break;
    case "organizationMargeSelectOrgClick": // 組織管理-編集-合併-組織選択押下
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.marge,
          sub_scene4_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationMargeList": // 組織管理-編集-合併-組織一覧
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.marge,
          sub_scene4_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.search,
        },
        callback
      );
      break;
    case "organizationMargeListSelect": // 組織管理-編集-合併-組織一覧-選択
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.marge,
          sub_scene4_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.select,
        },
        callback
      );
      break;
    case "organizationMargeClick": // 組織管理-編集-合併-変更押下
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.marge,
          sub_scene4_id: apiLog.subSceneId.confirm,
          sub_scene5_id: apiLog.subSceneId.modify,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "organizationMargeCancel": // 組織管理-編集-合併-キャンセル押下
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.organizationManagement,
          sub_scene2_id: apiLog.subSceneId.edit,
          sub_scene3_id: apiLog.subSceneId.marge,
          sub_scene4_id: apiLog.subSceneId.confirm,
          sub_scene5_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "articleManagerClick": // 物件管理-押下
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.articleManagement,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "articleManagerMoveClick": // 物件管理-移動押下
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.articleManagement,
          sub_scene2_id: apiLog.subSceneId.move,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "articleManagerMoveOrgList": // 物件管理-移動-組織一覧
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.articleManagement,
          sub_scene2_id: apiLog.subSceneId.move,
          sub_scene3_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.search,
        },
        callback
      );
      break;
    case "articleManagerMoveOrgListSelect": // 物件管理-移動-組織一覧-選択
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.articleManagement,
          sub_scene2_id: apiLog.subSceneId.move,
          sub_scene3_id: apiLog.subSceneId.organization,
          action_id: apiLog.actionId.select,
        },
        callback
      );
      break;
    case "articleManagerMoveOrgListActivate": // 物件管理-移動-組織一覧-確認押下
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.articleManagement,
          sub_scene2_id: apiLog.subSceneId.move,
          sub_scene3_id: apiLog.subSceneId.confirm,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "articleManagerMoveOrgListCancel": // 物件管理-移動-組織一覧-戻る押下
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.articleManagement,
          sub_scene2_id: apiLog.subSceneId.move,
          sub_scene3_id: apiLog.subSceneId.return,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "articleManagerMoveRegister": // 物件管理-移動-登録押下
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.articleManagement,
          sub_scene2_id: apiLog.subSceneId.move,
          sub_scene3_id: apiLog.subSceneId.confirm,
          sub_scene4_id: apiLog.subSceneId.entry,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
    case "articleManagerMoveCancel": // 物件管理-移動-キャンセル押下
      addCompanyLog(
        {
          scene_id: apiLog.sceneId.articleManagement,
          sub_scene2_id: apiLog.subSceneId.move,
          sub_scene3_id: apiLog.subSceneId.confirm,
          sub_scene4_id: apiLog.subSceneId.cancel,
          action_id: apiLog.actionId.click,
        },
        callback
      );
      break;
  }
};